import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../../models/user';
import {RestApiService} from '../../../services/api';
import {UserGroup} from '../../../models/user-group';

@Component({
    selector: 'app-user-label',
    templateUrl: './user-label.component.html',
    styleUrls: ['./user-label.component.scss']
})
export class UserLabelComponent implements OnInit, OnDestroy {

    // =============================================
    //  FIELDS
    // =============================================

    @Input()
    user: User;

    @Input()
    userId: number;

    @Input()
    code: string;

    @Input()
    group: UserGroup;

    @Input()
    /**
     * Mostra il nome dell'utente al posto del codice
     */
    showFullName = false;

    cachedCode = '';
    cachedFullName = '';

    // =============================================
    //  CONSTRUCTOR
    // =============================================

    constructor(private api: RestApiService) {
    }

    async ngOnInit() {
        await this.loadData();
    }

    ngOnDestroy() {
    }


    // =============================================
    //  PRIVATE METHODS
    // =============================================

    private async loadData() {
        if (this.userId) {
            const response = await this.api.usersCached.getById(this.userId);
            this.user = response.data;
        }
        if (this.code) {
            const userResponse = await this.api.usersCached.getByUserCode(this.code);
            if (userResponse.data) {
                this.user = userResponse.data;
            } else {
                const groupResponse = await this.api.userGroupsCached.getByGroupCode(this.code);
                if (groupResponse.data) {
                    this.group = groupResponse.data;
                }
            }
        }
        this.cachedFullName = this.getFullName();
        this.cachedCode = this.getCode();
    }

    // =============================================
    //  METHODS
    // =============================================

    private getFullName() {
        if (this.user) {
            return this.user.fullName;
        }
        if (this.group) {
            return this.group.groupLabel;
        }
        return '';
    }

    private getCode() {
        if (this.user) {
            return this.user.codice;
        }
        if (this.group) {
            return this.group.groupCodice;
        }
        return this.code ?? '';
    }
}
