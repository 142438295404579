import {BaseFilterRequest} from './base-filter-request';
import {FString} from '../../services/api/filters/string-filter';
import {FilterClientRequest} from './filter-client-request';
import {FDate} from '../../services/api/filters/date-filter';
import {FInt} from '../../services/api/filters/int-filter';
import {Stringifier} from '../../services/stringifier.service';
import {FEnum} from '../../services/api/filters/enum-filter';

@Stringifier
export class FilterRemoteDevicesRequest extends BaseFilterRequest {

    deviceId: FInt;

    name: FString;

    telephone: FString;

    lastConnectionUpdate: FDate;

    lastStatus: FilterRemoteDeviceLogRequest;

    lastCommand: FilterRemoteDeviceLogRequest;

    masterDevice: FilterRemoteDevicesRequest;

    client: FilterClientRequest;

    isMaster: boolean;

    static getStringifier(level: number): FilterRemoteDevicesRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            deviceId: null,
            name: null,
            telephone: null,
            lastConnectionUpdate: null,
            lastStatus: FilterRemoteDeviceLogRequest.getStringifier(level - 1),
            lastCommand: FilterRemoteDeviceLogRequest.getStringifier(level - 1),
            masterDevice: FilterRemoteDevicesRequest.getStringifier(level - 1),
            client: FilterClientRequest.getStringifier(level - 1),
            isMaster: null,
        } as FilterRemoteDevicesRequest;
    }
}

@Stringifier
export class FilterRemoteDeviceLogRequest extends BaseFilterRequest {
    submittedAt?: FDate;
    deliveredAt?: FDate;
    status?: FEnum;
    deviceStatus?: FEnum;

    static getStringifier(level: number): FilterRemoteDeviceLogRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            submittedAt: null,
            deliveredAt: null,
            status: null,
            deviceStatus: null,
        } as FilterRemoteDeviceLogRequest;
    }
}
