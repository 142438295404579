import {User} from './user';
import {Type} from 'class-transformer';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class AgentZone {
    agentZoneId: number;

    userId: number;

    @Type(() => User)
    user: User;

    zucchettiCode: string;

    description: string;

    @Type(() => Date)
    vaidFromDate?: Date;

    @Type(() => Date)
    obsoleteFromDate?: Date;

    static getStringifier(level: number): AgentZone | null {
        if (level < 0) {
            return null;
        }
        return {
            agentZoneId: null,
            userId: null,
            user: User.getStringifier(level - 1),
            zucchettiCode: null,
            description: null,
            vaidFromDate: null,
            obsoleteFromDate: null,
        } as AgentZone;
    }
}
