import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-td-unread-messages',
    templateUrl: 'td-unread-messages.component.html',
    styleUrls: ['./common-flag-styles.scss']
})
export class TdUnreadMessagesComponent {
    @Input() unreadMessages = 0;
    @Input() unreadClientMessages = 0;
    @Input() unreadProviderMessages = 0;
    @Input() unreadChatsMessages = 0;

    get text() {
        return;
    }

    private _src: string = null;

    get src() {
        if (this._src === null) {
            if (this.unreadMessages <= 0) {
                this._src = '#';
            }
            const flag = (this.unreadClientMessages ? 'C' : '-') // C: Client
                + (this.unreadChatsMessages ? 'I' : '-') // I: internal
                + (this.unreadProviderMessages ? 'P' : '-'); // P: provider
            // il flag e` la sequenza delle lettere 'C', 'I' e 'P'.
            // ogni lettera denota la presenza di un nuovo messaggio per categoria (C = client, I = intrenal chat, P = provider).
            // l'assenza di un messaggio è demarcato da un trattino '-'.
            // così viene generato il nome del file. Per es.:
            // "C-P": Icona per Client e Provider senza Internal Chat.
            // "C--": Icona solo per client
            // "CIP": Icona per client, internal chat e provider
            // "-IP": Icona per internal chat e provider
            // ...eccetera...
            this._src = `/assets/images/unread-tickets/${flag}.png`;

        }
        return this._src;
    }

    get tooltipMessage() {
        if (this.unreadMessages <= 0) { return 'Nessun messaggio da leggere'; }

        const plural = (n: number) => n > 1 ? 'messaggi' : 'messaggio';

        const messages: string[] = [];

        if (this.unreadClientMessages > 0) {
            messages.push(this.unreadClientMessages + ' ' + plural(this.unreadClientMessages) + ' dal cliente');
        }

        if (this.unreadProviderMessages > 0) {
            messages.push(this.unreadProviderMessages + ' ' + plural(this.unreadProviderMessages) + ' da provider');
        }

        if (this.unreadChatsMessages > 0) {
            messages.push(this.unreadChatsMessages + ' ' + plural(this.unreadChatsMessages) + ' di chat interna');
        }

        return messages.reduce((old, current) => old + '\n' + current);
    }

}
