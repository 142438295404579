import {Component, Input, OnInit} from '@angular/core';
import {Contract, ContractStateExpanded} from '../../../models/contract';

@Component({
    selector: 'app-contract-status-icon',
    templateUrl: './contract-status-icon.component.html',
    styleUrls: ['./contract-status-icon.component.scss']
})
export class ContractStatusIconComponent implements OnInit {

    @Input()
    contract: Contract;

    contractStatuses = ContractStateExpanded;

    constructor() {
    }

    ngOnInit(): void {
    }

}
