import {Type} from 'class-transformer';
import {TicketGroupSide} from '../tickets/ticket-group-side';
import {Stringifier} from '../../services/stringifier.service';
import {TicketGroup} from '../tickets/ticket-group';

@Stringifier
export class UniformedMessageEntry {
    messageId: string;
    ticketGroupId: number;
    ticketId: string;
    title: string;
    text: string;
    @Type(() => Date)
    date: Date;
    loginName: string;
    side: TicketGroupSide;

    @Type(() => TicketGroup)
    ticketGroup: TicketGroup;

    static getStringifier(level: number): UniformedMessageEntry | null {
        if (level < 0) {
            return null;
        }
        return {
            messageId: null,
            ticketGroupId: null,
            ticketId: null,
            title: null,
            text: null,
            date: null,
            loginName: null,
            side: null,
            ticketGroup: TicketGroup.getStringifier(level - 1),
        } as UniformedMessageEntry;
    }
}
