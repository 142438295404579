import {Component, Input, OnInit} from '@angular/core';
import {ProviderTicket} from '../../../../models/tickets/provider/provider-ticket';
import {ClientTicket} from '../../../../models/tickets/client/client-ticket';

@Component({
    selector: 'app-td-ticket-group-link',
    template: `
        <app-ticket-group-link *ngFor="let t of tickets" [ticketGroupId]="ticketGroupId"
            [label]="label(t) | ticketCode">
        </app-ticket-group-link>
    `,
})
export class TdTicketGroupLinkComponent implements OnInit {

    @Input()
    ticketGroupId: number;

    @Input()
    tickets: ProviderTicket[] | ClientTicket[];

    constructor() {
    }

    ngOnInit(): void {
    }

    label(ticket: ProviderTicket | ClientTicket): string {
        return (ticket instanceof ProviderTicket) ? (ticket.idNgi) : ticket.id.toString();
    }
}
