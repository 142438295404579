import {TicketFlag} from './ticket-flag';
import {TicketAlarm} from './ticket-alarm';
import {TicketChat} from './ticket-chat';
import {TicketAssignment} from './ticket-assignment';
import {TicketCheckClosure} from './ticket-check-closure';
import {Type} from 'class-transformer';
import {ClientTicket} from './client/client-ticket';
import {ProviderTicket} from './provider/provider-ticket';
import {Client} from '../clients/client';
import {Contract} from '../contract';
import {Stringifier} from '../../services/stringifier.service';
import {TicketGroupRdp} from './ticket-group-rdp';

/**
 * Aggrega gli elementi di tipo TicketListItem in un unico gruppo
 */
@Stringifier
export class TicketGroup {

    id: number;

    @Type(() => Date)
    created: Date;

    @Type(() => Date)
    lastUpdate: Date;

    @Type(() => Date)
    ts: Date;

    @Type(() => Client)
    client: Client;

    @Type(() => Contract)
    contract: Contract;
    ignora?: boolean;

    @Type(() => ProviderTicket)
    providerTickets: ProviderTicket[];

    @Type(() => ClientTicket)
    clientTickets: ClientTicket[];

    @Type( () => TicketFlag)
    flags: TicketFlag[];

    @Type(() => TicketAlarm)
    alarms: TicketAlarm[];
    @Type(() => TicketChat)
    chats: TicketChat[];

    @Type(() => TicketAssignment)
    assignments: TicketAssignment[];
    @Type(() => TicketGroupRdp)
    disserviceRequests: TicketGroupRdp[];
    @Type(() => TicketCheckClosure)
    ticketgroupcheckchiusura: TicketCheckClosure[];
    userNgi: string;
    userMft: string;

    get isNew() {
        return this.id === 0;
    }

    get assigned() {
        return this.userNgi || this.userMft;
    }

    get activeDisserviceRequest() {
        return this.disserviceRequests?.find(rdp => !rdp.annullato && !rdp.concluso);
    }

    get disserviceRequestsHistory() {
        return this.disserviceRequests?.filter(rdp => rdp.annullato || rdp.concluso);
    }

    get canceledDisserviceRequest() {
        return this.disserviceRequests?.filter(rdp => rdp.annullato);
    }

    get completedDisserviceRequest() {
        return this.disserviceRequests?.filter(rdp => rdp.concluso);
    }

    static getStringifier(level: number): TicketGroup | null {
        if (level < 0) {
            return null;
        }
        return {
            id: null,
            created: null,
            ts: null,
            client: Client.getStringifier(level - 1),
            contract: Contract.getStringifier(level - 1),
            ignora: null,
            providerTickets: null,
            clientTickets: null,
            flags: null,
            alarms: null,
            chats: null,
            assignments: null,
            disserviceRequests: null,
            ticketgroupcheckchiusura: null,
            userNgi: null,
            userMft: null,
        } as TicketGroup;
    }
}
