import {BaseFilterRequest} from '../base-filter-request';
import {FString} from '../../../services/api/filters/string-filter';
import {Stringifier} from '../../../services/stringifier.service';
import {FEnum} from '../../../services/api/filters/enum-filter';

@Stringifier
export class FilterEasyCastContractsRequest extends BaseFilterRequest {
    contractId?: number;
    clientBusinessName?: FString;
    clientEmail?: FString;
    contractStatus?: FEnum;

    sequentialRequestId: number;

    static getStringifier(level: number): FilterEasyCastContractsRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            contractId: null,
            clientBusinessName: null,
            clientEmail: null,
            contractStatus: null,
            sequentialRequestId: null,
        } as FilterEasyCastContractsRequest;
    }
}
