import {Component, Input, ViewChild} from '@angular/core';
import {FilterMatchMode, SelectItem} from 'primeng/api';
import {DateFilters} from '../../../../services/api/filters/date-filter';
import {ColumnFilter} from 'primeng/table';
import {AppDatePipe} from '../../../../pipes/app-date.pipe';

const noFilterApplied = 'Non filtrato';

@Component({
    selector: 'app-table-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent {

    @ViewChild('columnFilter')
    columnFilter: ColumnFilter;

    @Input() field: string;

    defaultMatch = DateFilters.DateIs;

    appliedTextualFilter = noFilterApplied;

    get values(): Date[] {
        this.columnFilter.fieldConstraints[0].value ??= [null];
        return this.columnFilter.fieldConstraints[0].value;
    }

    customMatchModeOptions = [
        {icon: 'fas fa-equals', label: 'Uguale al giorno', value: DateFilters.DateIs},
        {icon: 'fas fa-greater-than-equal', label: 'A partire dal giorno', value: DateFilters.DateAfterInclusive},
        {icon: 'fas fa-less-than-equal', label: 'Fino al giorno', value: DateFilters.DateBeforeInclusive},
        {label: 'Compreso tra i giorni', value: DateFilters.DateBetween},
    ] as SelectItem[];

    selectedModeOption: SelectItem = this.customMatchModeOptions[0];

    constructor(private datePipe: AppDatePipe) {
    }

    onChangeMode(selectedMode: SelectItem) {
        this.selectedModeOption = selectedMode;
        this.columnFilter.fieldConstraints[0].matchMode = selectedMode.value;
        if (selectedMode.value === DateFilters.DateBetween) {
            if (this.values.length === 1) {
                this.values.push(null);
            }
        } else {
            if (this.values.length === 2) {
                this.values.pop();
            }
        }
    }

    applyFilter(index: number, date: Date, filter: any) {
        this.values[index] = date;
        filter(this.values);
        this.appliedTextualFilter = this.getTextualFilter();
    }

    getTextualFilter() {
        if (this.values.length === 1) {
            if (this.values[0] == null) {
                return noFilterApplied;
            }
            const date = this.datePipe.transform(this.values[0], 'dd/MM/yyyy').toString();
            return `<i class="${this.selectedModeOption.icon}"></i> ${date}`;
        } else {
            if (this.values[0] == null || this.values[1] == null) {
                return noFilterApplied;
            }
            return '<i class="fas fa-greater-than-equal"></i>' +
                this.datePipe.transform(this.values[0], 'dd/MM/yyyy')
                + ' <i class="fas fa-less-than-equal ml-3"></i>' +
                this.datePipe.transform(this.values[1], 'dd/MM/yyyy');

        }
    }
}
