import {
    AfterContentInit,
    Component, ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output, QueryList, TemplateRef,
    ViewChild
} from '@angular/core';
import {RestApiService} from '../../../services/api';
import {ActionButtonType} from '../action-buttons/action-button.component';
import {debounceTime, Subject, Subscription} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PrimeTemplate} from 'primeng/api';

@UntilDestroy()
@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, OnDestroy, AfterContentInit {

    private static inputElementCounter = 1;

    private readonly _inputElementId: number;

    get inputElementId(): string {
        return 'inputElement' + this._inputElementId;
    }

    @Input()
    type: InputType = 'text';

    @Input()
    minValue = 0;

    @Input()
    maxValue?: number = undefined;

    @Input()
    autocomplete: 'on' | 'off' = 'on';

    @Input()
    placeholder = '';

    @Input()
    valueClass = '';

    @Input()
    icon = '';

    @Input()
    label = '';

    get hasLabel(): boolean {
        return this.label !== '';
    }

    @Input()
    maxLength = 256;

    @Input()
    ignoreMaxLength = false;

    @Input()
    showLength = true;

    @Input()
    minLength = 0;

    @Input()
    showMaximizeTextareaButton = false;

    @Input()
    autofocus = false;

    maximizedTextArea = false;

    get showAddons(): boolean {
        return this.showLength && this.model || this.showMaximizeTextareaButton || this.customButtons != null;
    }

    customButtons: TemplateRef<any>;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;


    internalModelLength = 0;

    private _internalModel: any;

    set internalModel(value: any) {
        if (this.automaticCaps) {
            if (typeof value === 'string') {
                value = (value as string).toUpperCase();
            }
        }
        this._internalModel = value;
        this.debouncerModelChange.next(value);
    }

    get internalModel(): any {
        return this._internalModel;
    }

    private _originalModel: any;

    @Input()
    set model(value: any) {
        this._originalModel = value;
        this._internalModel = value;
        this.internalModelLength = value?.length;
    }

    get model(): any {
        return this._originalModel;
    }

    get isChanged(): boolean {
        return this.showChangedAlert && this._internalModel !== this._originalModel;
    }

    private debouncerModelChange: Subject<any> = new Subject<any>();

    private debouncerSubscription: Subscription | null;

    private _debounceDelay = 0;

    get debounceDelay(): number {
        return this._debounceDelay;
    }

    /**
     * Tempistica di debouncing
     * @param time se valorizzato a 0, il debouncing viene disattivato
     */
    @Input()
    set debounceDelay(time: number) {
        this._debounceDelay = time;
        if (this.debouncerSubscription) {
            this.debouncerSubscription.unsubscribe();
            this.debouncerSubscription = null;
        }
        if (time > 0) {
            this.debouncerSubscription = this.debouncerModelChange
                .pipe(untilDestroyed(this), debounceTime(time))
                .subscribe(value => {
                    this.internalModelLength = value?.length ?? 0;
                    this.modelChange.emit(value);
                });
        }
    }

    @Output()
    modelChange: EventEmitter<any> = new EventEmitter<any>();


    @Input()
    canBeRandomized = false;

    @Input()
    labelWidth = '200px';

    @Input()
    inputWidth = '200px';

    @Output() action: EventEmitter<string> = new EventEmitter();

    @Input()
    actionButtonLabel?: string = undefined;

    @Input()
    actionButtonTooltip?: string = undefined;

    @Input()
    actionButtonType?: ActionButtonType = undefined;

    @Input()
    disabled = false;

    @Input()
    disabledActionButton = false;

    @Input()
    readOnly = false;

    @Input()
    required = false;

    @Input()
    showChangedAlert = false;

    @Input()
    autoresize = true;

    @ViewChild('textarea')
    textarea: ElementRef<HTMLTextAreaElement>;

    @ViewChild('inputElement')
    inputElement: ElementRef<HTMLInputElement>;

    @Input()
    automaticCaps = false;

    /**
     * Imposta automaticamente il focus sull'input quando la condizione e` vera dopo "autoFocusDelay" ms
     */
    @Input()
    set autoFocusOn(value: boolean) {
        if (value && this.inputElementId) {
            // TODO: utilizzare ViewChild di Angular...
            setTimeout(() => {
                document.getElementById(this.inputElementId)?.focus();
            }, this.autoFocusDelay);
        }
    }

    @Input()
    autoFocusDelay = 50;

    get hasActionButton() {
        return this.action.observers.length > 0;
    }

    constructor(private api: RestApiService) {
        this._inputElementId = ++InputComponent.inputElementCounter;
    }

    ngOnDestroy(): void {
    }

    ngOnInit(): void {
        this.debounceDelay = 200; // valore di default del debouncing time
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'buttons':
                    this.customButtons = item.template;
                    break;
            }
        });
    }

    async randomizeContent() {
        const response = await this.api.tools.getRandomText();
        this.model = response.data;
    }

    scrollToBottom() {
        this.textarea.nativeElement.scrollTop = this.textarea.nativeElement.scrollHeight;
    }

    maximize() {
        this.maximizedTextArea = true;
    }

    setFocus() {
        this.inputElement.nativeElement.focus();
    }
}

export type InputType = 'text' | 'textarea' | 'label' | 'number' | 'wysiwyg' | 'time' | 'password';
