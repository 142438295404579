<span [ngSwitch]="contract.stato">
    <i *ngSwitchCase="contractStatuses.Requiring"
       pTooltip="Richiesta"
       class="fa fa-gear"></i>
    <i *ngSwitchCase="contractStatuses.Ko"
       pTooltip="Ko"
       class="fa fa-skull"></i>
    <i *ngSwitchCase="contract.isClosedState() ? contract.stato : ''"
       class="text-error fa fa-cross" pTooltip="Linea chiusa"></i>
</span>
