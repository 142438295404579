import {Component, Input} from '@angular/core';
import {TicketFlag} from '../../../../models/tickets/ticket-flag';

@Component({
    selector: 'app-td-flags',
    template: `<span *ngFor="let flag of flags"
                     [style]="'color: #' + flag.color"
                     [pTooltip]="flag.descr"
                     tooltipPosition="top"
                     class="info-icon">
                                {{flag.simbolo}}
                            </span>`,
    styleUrls: ['./common-flag-styles.scss']
})
export class TdFlagsComponent {
    @Input() flags: TicketFlag[];
}
