<div class="flex">
    <span class="textual-filter"
          (click)="columnFilter.toggleMenu()"
          [innerHTML]="appliedTextualFilter"></span>
    <p-columnFilter type="date" #columnFilter
                    [field]="field"
                    [showClearButton]="true"
                    [matchMode]="defaultMatch"
                    [matchModeOptions]="customMatchModeOptions"
                    [showMatchModes]="false"
                    [showOperator]="false"
                    [showAddButton]="false"
                    [showMenu]="true"
                    display="form"
    >
        <ng-template pTemplate="header">
            <div class="p-2">
                Filtro da applicare:<br>
                <app-dropdown [dropdownItems]="columnFilter.matchModeOptions"
                              [model]="selectedModeOption"
                              optionIcon="icon"
                              (modelChange)="onChangeMode($event)"
                ></app-dropdown>
            </div>
        </ng-template>
        <ng-template pTemplate="value" let-value let-filter="filterCallback">
            <div class="p-2 flex">
                <app-datetime [model]="values[0]" (modelChange)="applyFilter(0, $event, filter)"
                              [showTime]="false"></app-datetime>
                <app-datetime *ngIf="values.length > 1" [model]="values[1]" class="pl-2"
                              (modelChange)="applyFilter(1, $event, filter)" [showTime]="false"></app-datetime>
            </div>
        </ng-template>
    </p-columnFilter>
</div>
