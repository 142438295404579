<strong class="default-text"><i class="pi pi-envelope mr-2"></i>Ticket</strong>
<app-critical-tickets-list
    class="ml-3"
    [asIcon]="true"></app-critical-tickets-list>

<p-divider layout="vertical" class="inline-block"></p-divider>

<app-input class="inline-block mr-4" placeholder="Inserisci login" actionButtonLabel="Apri ticket"
           (action)="newTicketFromLogin($event)"
           [showLength]="false"></app-input>

<app-action-button
    label="Apri ticket interno"
    (click)="newInternalTicket()"
></app-action-button>
<p-divider layout="vertical" class="inline-block ml-1"></p-divider>

<app-ticket-message-search-button
    (search)="searchContent($event)"
></app-ticket-message-search-button>

<p-divider layout="vertical" class="inline-block ml-1"></p-divider>

<a pButton
   label="Modifica colori linee"
   class="p-button-link"
   target="_blank"
   [href]="editLinesColorsUrl"></a>
