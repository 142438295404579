import {Filter, FilterRule} from './filter';


export enum DateFilters {
    DateIs = 'dateIs',
    DateIsNot = 'dateIsNot',
    DateBefore = 'dateBefore',
    DateAfter = 'dateAfter',
    DateBetween = 'dateBetween',
    DateBeforeInclusive = 'dateBeforeInclusive',
    DateAfterInclusive = 'dateAfterInclusive'
}


export type DateFilter = Filter<Date, DateFilters>;

export type FDate = FilterRule<DateFilter>;
