import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionStorageService} from '../../../../../services/session-storage.service';
import {AclPermission} from '../../../../../models/acl/acl-group';

@Component({
    selector: 'app-ticket-message-search-button',
    templateUrl: './ticket-message-search-button.component.html',
    styleUrls: ['./ticket-message-search-button.component.scss']
})
export class TicketMessageSearchButtonComponent implements OnInit {

    @Input()
    searchText = '';

    @Output()
    search = new EventEmitter<string>();

    disableSearchContent = true;

    minLengthSearch = 5;

    constructor(private sessionService: SessionStorageService) {
    }

    ngOnInit(): void {
        this.minLengthSearch = this.sessionService.hasPermission(AclPermission.TicketContentSearchNoLimit)
            ? 0 : 5;
        this.checkMinLength(this.searchText);
    }

    checkMinLength(value: string) {
        this.disableSearchContent = !value || value.length < this.minLengthSearch;
    }
}
