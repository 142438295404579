import {RemoteDeviceType} from './remote-device-type';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class RemoteDeviceCommand {
    commandId?: number;

    name: string;
    command: string;

    description?: string;

    parameters?: RemoteDeviceCommandParameter[];

    deviceTypeId?: number;
    deviceType?: RemoteDeviceType;

    static getStringifier(level: number): RemoteDeviceCommand | null {
        if (level < 0) {
            return null;
        }
        return {
            commandId: null,
            name: null,
            command: null,
            description: null,
            parameters: null,
            deviceTypeId: null,
            deviceType: RemoteDeviceType.getStringifier(level - 1),
        } as RemoteDeviceCommand;
    }
}


export interface RemoteDeviceCommandParameter {
    name: string;
    description: string;
    placeholder: string;
}
