import {BooleanFilter, FBoolean} from '../../services/api/filters/boolean-filter';
import {FInt} from '../../services/api/filters/int-filter';
import {FString} from '../../services/api/filters/string-filter';
import {Stringifier} from '../../services/stringifier.service';
import {BaseFilterRequest} from './base-filter-request';
import {FilterContractRequest} from './filter-contract-request';
import {FilterLoginClientRequest} from './filter-login-client.request';

@Stringifier
export class FilterDeviceTemplateRequest extends BaseFilterRequest {
    name?: FString;
    isDeleted?: boolean;

    static getStringifier(level: number): FilterDeviceTemplateRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            name: null,
            isDeleted: null,
        } as FilterDeviceTemplateRequest;
    }
}

@Stringifier
export class FilterDeviceRequest extends BaseFilterRequest {
    name?: FString;
    deviceTemplate?: FilterDeviceTemplateRequest;
    contract?: FilterContractRequest;
    isDeleted?: FBoolean;

    sequentialRequestId?: number;

    static getStringifier(level: number): FilterDeviceRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            name: null,
            deviceTemplate: FilterDeviceTemplateRequest.getStringifier(level - 1),
            contract: FilterContractRequest.getStringifier(level - 1),
            isDeleted: null,
            sequentialRequestId: null,
        } as FilterDeviceRequest;
    }
}

