import {Type} from 'class-transformer';
import {Agent} from '../agent';
import {AgentZone} from '../agent-zone';
import {ContractClient} from '../contract-client';
import {ContractTree} from '../contract-tree';
import {ClientFlagAssociation} from './client-flag-association';
import {ClientFlag} from './client-flag';
import {User} from '../user';
import {ClientReminder, IClientReminder} from './client-reminder';
import {Stringifier} from '../../services/stringifier.service';

// tslint:disable-next-line:no-empty-interface
export interface IClient extends Client {
}

@Stringifier
export class Client {
    clientId: number;
    tipo: ClientType;
    ragioneSociale: string;
    username: string;
    password: string;
    email: string;
    emailAmm: string;
    emailFatt: string;
    cf: string;
    piva: string;
    indirizzo: string;
    numeroCivico: string;
    provincia: string;
    comune: string;
    cap: string;
    localita: string;
    telefono: string;
    cellulare: string;
    telefonoTec: string;
    idZucchetti: string;
    enableSangomaRecall: boolean;
    dirig: boolean;

    pec: string;

    servicesCount: number;
    activeServicesCount: number;

    /**
     * Indica se il cliente ha sms Alert attivo oppure no
     */
    smsAlert: boolean;
    pag: string;

    /**
     * Indica se ha un blocco amministrativo
     */
    captive: boolean;
    @Type(() => Agent)
    idAgenteZucchettiNavigation: Agent;
    idAgenteZucchetti: number;

    @Type(() => AgentZone)
    agentZone: AgentZone;

    @Type(() => ClientFlagAssociation)
    clientFlags: ClientFlagAssociation[];

    @Type(() => ContractClient)
    contractClientAssociations: ContractClient[];

    @Type(() => ContractTree)
    contractClientTree: ContractTree[];

    @Type(() => ClientReminder)
    clientReminders: IClientReminder[];

    get fullAddress() {
        return `${this.indirizzo}, ${this.comune} (${this.provincia})`;
    }

    getPhones(): string[] {
        return this.telefono?.split(';') ?? [];
    }

    getMobilePhones(): string[] {
        return this.cellulare?.split(';') ?? [];
    }

    getTechnicalPhones(): string[] {
        return this.telefonoTec?.split(';') ?? [];
    }

    getEmails(): string[] {
        return this.email?.split(';') ?? [];
    }

    getAdministrativeEmails(): string[] {
        return this.emailAmm?.split(';') ?? [];
    }

    getInvoiceEmails(): string[] {
        return this.emailFatt?.split(';') ?? [];
    }

    getPercentage() {
        return (this.activeServicesCount / this.servicesCount) * 100;
    }

    getAllEmails() {
        const allEmails = [];
        if (this.email) {
            allEmails.push(...this.email.split(';'));
        }
        if (this.emailFatt) {
            allEmails.push(...this.emailFatt.split(';'));
        }
        if (this.emailAmm) {
            allEmails.push(...this.emailAmm.split(';'));
        }
        return [...new Set(allEmails.filter(x => x).map(x => x.trim()).filter(x => x.length > 0))];
    }

    static getStringifier(level: number): Client | null {
        if (level < 0) {
            return null;
        }
        return {
            clientId: null,
            tipo: null,
            ragioneSociale: null,
            username: null,
            password: null,
            email: null,
            emailAmm: null,
            emailFatt: null,
            cf: null,
            piva: null,
            indirizzo: null,
            numeroCivico: null,
            provincia: null,
            comune: null,
            cap: null,
            localita: null,
            telefono: null,
            cellulare: null,
            telefonoTec: null,
            idZucchetti: null,
            enableSangomaRecall: null,
            dirig: null,
            pec: null,
            servicesCount: null,
            activeServicesCount: null,
            smsAlert: null,
            pag: null,
            captive: null,
            idAgenteZucchettiNavigation: Agent.getStringifier(level - 1),
            idAgenteZucchetti: null,
            agentZone: AgentZone.getStringifier(level - 1),
            clientFlags: null,
            contractClientAssociations: null,
            contractClientTree: null,
            clientReminders: null,
        } as Client;
    }
}

export enum ClientType {
    Client = 'C',
    Supplier = 'F'
}

export class LocalClientFlagAssociation {
    clientFlagAssociationId: number;
    clientFlagId: number;
    @Type(() => ClientFlag)
    flag: ClientFlag;
    clientId: number;
    @Type(() => Client)
    client?: IClient;
    @Type(() => Date)
    createdOn: Date;
    createdByUserId: number;
    @Type(() => User)
    createdByUser: User;
    annotation: string;
}

