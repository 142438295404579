import {Component, Input, OnInit} from '@angular/core';
import {Contract} from '../../models/contract';
import {RestApiService} from '../../services/api';
import {LineColor} from '../../models/line-color';

@Component({
    selector: 'app-contract-colorized-line',
    template: `
        <div
            [style.background]="background ?? 'none'"
            [style.color]="foreground ?? 'none'"
            [style.width]="width"
        >{{lineName ?? 'nessuna linea'}}</div>`,
    styles: [`
        div {
            padding: 0.3rem 0.5rem 0.3rem 0.5rem;
            width: 100%;
            word-break: break-word;
        }
    `]
})
export class ContractColorizedLineComponent implements OnInit {
    @Input() contract?: Contract;
    @Input() name?: string;
    @Input() backgroundColor?: string;
    @Input() color?: string;
    @Input() width = 'auto';

    _lineColor?: LineColor;

    constructor(private api: RestApiService) {
    }

    get lineName() {
        return this.name ? this.name : this.contract?.servizioNavigation?.nameOld;
    }

    async ngOnInit() {
        if (this.contract?.servizioNavigation?.nameOld) {
            this._lineColor = (await this.api.preferencesCached
                .getLineColorByName(this.contract.servizioNavigation.nameOld)).data;
        }
    }

    get background() {
        return (this.backgroundColor
            ? this.backgroundColor
            : this._lineColor?.colSfondo);
    }

    get foreground() {
        if (this.color) { return this.color; }
        if (this._lineColor?.colTesto) { return this._lineColor?.colTesto; }
        if (this.background) {return 'black'; }
        return null;
    }
}
