<span *ngIf="!client; else interno">Ticket interno</span>
<ng-template #interno>
    <div class="flex" [class.align-items-center]="alignCenter" [class.justify-content-center]="alignCenter">
        <span *ngIf="client.dirig && showAttributes" class="fa fa-user-tie ticket-icon mr-2"
              pTooltip="Cliente Dirigenziale, ATTENZIONE!"></span>
        <div class="block">
            <span appCopyable [appCopyableValue]="client.ragioneSociale" class="fiscalName"
                  [innerHTML]="client.ragioneSociale | highlightWord:highlightText"></span>
            <button *ngIf="showSearchTicket" pButton type="button" icon="fa fa-search"
                    class="p-button-sm p-button-text p-0 m-0"
                    pTooltip="Mostra tutti i ticket del cliente {{client.ragioneSociale}}"
                    tooltipPosition="top"
                    [class.ml-3]="showButtonLabels"
                    (click)="searchTicketsByName()"
                    [label]="showButtonLabels ? 'Mostra ticket' : ''"
            ></button>
            <button *ngIf="showGenericTicketButton" pButton type="button" icon="fa fa-wrench"
                    class="p-button-sm p-button-text p-button-success p-0 m-0"
                    [class.ml-3]="showButtonLabels"
                    pTooltip="Apri un nuovo ticket di Assistenza Generica"
                    tooltipPosition="top"
                    (click)="openGenericTicket()"
                    [label]="showButtonLabels ? 'Nuovo ticket' : ''"
            ></button>
            <button *ngIf="showAgent && client.idAgenteZucchettiNavigation" pButton
                    class="p-button-text p-0"
                    icon="fa-solid fa-briefcase"
                    [class.ml-3]="showButtonLabels"
                    [pTooltip]="'Agente: ' + client.idAgenteZucchettiNavigation.descrizione"
                    (click)="openAgentInfo()"
                    [label]="showButtonLabels ? 'Dati agente' : ''"
            ></button>
            <ng-template [ngIf]="showDeviceMap">
                <button pButton icon="fa fa-diagram-project" *appAcl="AclPermission.ViewDevicesMap"
                        class="p-button-sm p-button-text p-0 m-0"
                        [class.ml-3]="showButtonLabels"
                        pTooltip="Apri la mappa dei dispositivi del cliente {{client.ragioneSociale}}"
                        (click)="openDeviceMap()"
                        [label]="showButtonLabels ? 'Device map' : ''"
                ></button>
            </ng-template>
            <ng-template [ngIf]="showAccessLog && client">
                <button pButton icon="fa fa-key"
                        class="p-button-sm p-button-text p-0 m-0"
                        [class.ml-3]="showButtonLabels"
                        pTooltip="Mostra i log di accesso del cliente {{client.ragioneSociale}}"
                        (click)="openAccessLog()"
                        [label]="showButtonLabels ? 'Log accesso area cliente' : ''"
                ></button>
                <p-dialog [(visible)]="accessLogVisible" header="Log accessi area cliente"
                          appendTo="body"
                          [style]="{width: '50vw', height: '80hw'}"
                    >
                    <app-client-access-details [clientId]="client.clientId"></app-client-access-details>
                </p-dialog>
            </ng-template>
            <ng-template [ngIf]="showTelephones">
                <br>
                <span>Tel: <strong appCopyable *ngFor="let tel of telephones" class="pr-1">{{ tel }}</strong></span><br>
                <span>Mob: <strong appCopyable *ngFor="let mob of mobilePhones"
                                   class="pr-1">{{ mob }}</strong></span><br>
                <span>Tec: <strong appCopyable *ngFor="let tec of technicalPhones"
                                   class="pr-1">{{ tec }}</strong></span>
            </ng-template>
        </div>
    </div>
</ng-template>

