import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {DeviceTemplateCustomFieldType, IDeviceTemplateCustomFieldType} from './DeviceTemplateCustomFieldType';
import {INetworkInterfaceTemplateCustomFieldType, NetworkInterfaceTemplateCustomFieldType} from './NetworkInterfaceTemplateCustomFieldType';

// tslint:disable-next-line:no-empty-interface
export interface ICustomFieldType extends CustomFieldType {
}

@Stringifier
export class CustomFieldType {
    customFieldTypeId: number;
    name: string;
    type: CustomFieldTypeEnum;

    @Type(() => NetworkInterfaceTemplateCustomFieldType)
    networkInterfaceTemplateCustomFieldTypes: INetworkInterfaceTemplateCustomFieldType[];
    @Type(() => DeviceTemplateCustomFieldType)
    deviceTemplateCustomFieldTypes: IDeviceTemplateCustomFieldType[];

    static getStringifier(level: number): CustomFieldType | null {
        if (level < 0) {
            return null;
        }
        return {
            customFieldTypeId: null,
            name: null,
            type: null,
            networkInterfaceTemplateCustomFieldTypes: null,
            deviceTemplateCustomFieldTypes: null,
        } as CustomFieldType;
    }
}

export enum CustomFieldTypeEnum {
    String,
    Integer,
    Float,
    Boolean,
    DateTime,
    IpV4Address,
    IpV6Address,
    MacAddress,
}
