import {TicketGroup} from '../tickets/ticket-group';
import {Type} from 'class-transformer';

export class TicketGroupDto extends TicketGroup {
    unread: number;
    unreadClients: number;
    unreadProviders: number;
    unreadChats: number;

    @Type(() => Date)
    lastClientMessageAt?: Date;
}
