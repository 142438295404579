import {Pipe, PipeTransform} from '@angular/core';
import {TicketType} from '../models/responses/ticket-type';

@Pipe({name: 'ticketType'})
export class TicketTypePipe implements PipeTransform {

    transform(value: TicketType | string): string {
        return TicketTypePipe.transform(value);
    }

    static transform(value: TicketType | string): string {
        switch (value) {
            case TicketType.Technical: return 'TEC';
            case TicketType.Administrative: return 'AMM';
            case TicketType.Unknown: return '';
        }
    }

}
