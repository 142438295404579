import {Filter, FilterRule} from './filter';
import {StringFilter, StringFilters} from "./string-filter";

export enum IntFilters {
    Equals = 'equals',
    NotEquals = 'notEquals',
    GreaterThan = 'greaterThan',
    LessThan = 'lessThan',
}

export type IntFilter = Filter<number, IntFilters>;

export class FInt extends FilterRule<IntFilter> {
    constructor(value?: number, filterType?: IntFilters) {
        super();
        if (value) {
            this.filters.push({
                filterType: filterType ?? IntFilters.Equals,
                values: [value]
            });
        }
    }
}

