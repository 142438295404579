import {Component, Input, OnInit} from '@angular/core';
import {RestApiService} from '../../../services/api';
import {ClientAccessDetails} from '../../../models/responses/client-access-details';
import {ClientAccessLog} from '../../../models/client-access-log';

@Component({
    selector: 'app-client-access-details',
    templateUrl: './client-access-details.component.html',
    styleUrls: ['./client-access-details.component.scss']
})
export class ClientAccessDetailsComponent implements OnInit {

    @Input()
    clientId: number;

    loadingError = false;
    loadingAccessDetails = true;

    accessLog: ClientAccessDetails;

    obj(item: ClientAccessLog) {
        return item;
    }
    constructor(private api: RestApiService) {
    }

    ngOnInit(): void {
        this.loadAccessDetails();
    }

    async loadAccessDetails() {
        this.loadingAccessDetails = true;
        try {
            const response = await this.api.clients.getAccessLog(this.clientId);
            this.accessLog = response.data;
            this.loadingError = false;
        } catch (e) {
            this.loadingError = true;
        } finally {
            this.loadingAccessDetails = false;
        }
    }
}
