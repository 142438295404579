import {BaseFilterRequest} from '../base-filter-request';
import {Stringifier} from '../../../services/stringifier.service';
import {FString} from '../../../services/api/filters/string-filter';
import {FBoolean} from '../../../services/api/filters/boolean-filter';
import {FDate} from '../../../services/api/filters/date-filter';
import {FInt} from '../../../services/api/filters/int-filter';
import {FEnum} from '../../../services/api/filters/enum-filter';


@Stringifier
export class FilterEasyCastUsersRequest extends BaseFilterRequest {
    userId: FInt;
    username: FString;
    businessName: FString;
    fiscalCode: FString;
    vatNumber: FString;
    address: FString;
    city: FString;
    province: FString;
    zipCode: FString;
    country: FString;
    note: FString;
    active: FBoolean;
    createdAt: FDate;
    userType: FEnum;
    parentUserId: FInt;

    static getStringifier(level: number): FilterEasyCastUsersRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            userId: null,
            username: null,
            businessName: null,
            fiscalCode: null,
            vatNumber: null,
            address: null,
            city: null,
            province: null,
            zipCode: null,
            country: null,
            note: null,
            active: null,
            createdAt: null,
            userType: null,
            parentUserId: null,
        } as FilterEasyCastUsersRequest;
    }
}

