<p-table #table styleClass="p-datatable p-datatable-striped p-datatable-sm p-datatable-gridlines"
         [lazy]="true"
         (onLazyLoad)="triggerLazyLoad.emit($event)"
         [value]="tickets.dataList"
         [rows]="pagination ? 250 : 100000000"
         [rowsPerPageOptions]="[100, 250, 500, 750, 1000]"
         [totalRecords]="tickets.count"
         [paginator]="pagination"
         [rowHover]="true"
         [sortField]="filterStringifier.unread | pascalCase"
         [sortOrder]="-1"
         [loading]="loading"
         [autoLayout]="true"
>
    <ng-template pTemplate="header">
        <tr class="table-header">
            <th *ngIf="showColumn('unread')" [pSortableColumn]="filterStringifier.unread.toString() | pascalCase"><span
                class="fa fa-envelope"></span>
                <p-sortIcon [field]="filterStringifier.unread.toString() | pascalCase"></p-sortIcon>
            </th>
            <th *ngIf="showColumn('flag')">Flag</th>
            <th *ngIf="showColumn('createdAt')"
                [pSortableColumn]="filterStringifier.created.toString() | pascalCase">Creazione
                <p-sortIcon [field]="filterStringifier.created.toString() | pascalCase"></p-sortIcon>
            </th>
            <th *ngIf="showColumn('updatedAt')"
                [pSortableColumn]="filterStringifier.lastUpdate.toString() | pascalCase">Ultima modifica
                <p-sortIcon [field]="filterStringifier.lastUpdate.toString() | pascalCase"></p-sortIcon>
            </th>
            <th *ngIf="showColumn('lastClientMessageDate')">Ultimo messaggio cliente
            </th>
            <th *ngIf="showColumn('user')">User</th>
            <th *ngIf="showColumn('clientTicket')">Ticket<br><small>cliente</small></th>
            <th *ngIf="showColumn('providerTicket')">Ticket<br><small>fornitore</small></th>
            <th *ngIf="showColumn('clientTitle')"
                [pSortableColumn]="filterStringifier.clientTickets.titolo.toString() | pascalCase">
                <div class="flex flex-row align-items-center justify-content-center">
                    <div>
                        Titolo<br>
                        <small>ticket cliente</small>
                    </div>
                    <p-sortIcon
                        [field]="filterStringifier.clientTickets.titolo.toString() | pascalCase"></p-sortIcon>
                </div>
            </th>
            <th *ngIf="showColumn('providerTitle')"
                [pSortableColumn]="filterStringifier.providerTickets.titoloNgi.toString() | pascalCase">
                <div class="flex flex-row align-items-center justify-content-center">
                    <div>
                        Titolo<br>
                        <small>ticket fornitore</small>
                    </div>
                    <p-sortIcon
                        [field]="filterStringifier.providerTickets.titoloNgi.toString() | pascalCase"></p-sortIcon>
                </div>
            </th>
            <th *ngIf="showColumn('type')">Tipo</th>
            <th *ngIf="showColumn('state')">Stato<br/><small>ticket cliente</small></th>
            <th *ngIf="showColumn('provider')">Provider</th>
            <th *ngIf="showColumn('contract')">Login</th>
            <th *ngIf="showColumn('line')">Linea</th>
            <th *ngIf="showColumn('clientName')"
                [pSortableColumn]="filterStringifier.client.ragioneSociale.toString() | pascalCase">
                Ragione sociale
                <p-sortIcon [field]="filterStringifier.client.ragioneSociale.toString() | pascalCase">
                </p-sortIcon>
            </th>
        </tr>
        <tr *ngIf="showFilters">
            <th *ngIf="showColumn('unread')"></th><!--MSG-->
            <th *ngIf="showColumn('flag')"></th><!--Flag-->
            <th *ngIf="showColumn('createdAt')">
                <app-table-date-filter [field]="filterStringifier.created.toString()"></app-table-date-filter>
            </th><!--Ultima modifica-->
            <th *ngIf="showColumn('updatedAt')">
                <app-table-date-filter
                    [field]="filterStringifier.lastUpdate.toString()"></app-table-date-filter>
            <th *ngIf="showColumn('lastClientMessageDate')">
            </th><!--Ultimo messaggio-->
            <th *ngIf="showColumn('user')">
                <app-table-enum-filter
                    placeholder="User"
                    [field]="filterStringifier.owners.toString()" [entries]="users">
                    <ng-template pTemplate="footer">
                        <div class="flex align-items-center p-2 mt-3">
                            <p-inputSwitch [ngModel]="showInactiveUsers"
                                           (ngModelChange)="loadUsersList($event)"
                            ></p-inputSwitch>
                            <span class="ml-2">Mostra utenti inattivi</span>
                        </div>
                    </ng-template>
                </app-table-enum-filter>
            </th><!--User-->
            <th *ngIf="showColumn('clientTicket')">
                <app-table-int-filter [field]="filterStringifier.clientTickets.id.toString()"
                                      inputWidth="100px">
                </app-table-int-filter>
            </th><!--ticket cliente-->
            <th *ngIf="showColumn('providerTicket')">
                <app-table-string-filter [field]="filterStringifier.providerTickets.idNgi.toString()"
                                         inputWidth="100px">
                </app-table-string-filter>
            </th><!--ticket fornitore-->
            <th *ngIf="showColumn('clientTitle')">
                <app-table-string-filter [field]="filterStringifier.clientTickets.titolo.toString()"
                                         inputWidth="150px">
                </app-table-string-filter>
            </th><!--titolo cliente-->
            <th *ngIf="showColumn('providerTitle')">
                <app-table-string-filter [field]="filterStringifier.providerTickets.titoloNgi.toString()"
                                         inputWidth="150px">
                </app-table-string-filter>
            </th><!--titolo fornitore-->
            <th *ngIf="showColumn('type')">
                <app-table-enum-filter
                    placeholder="Tipo"
                    [field]="filterStringifier.type.toString()" [entries]="ticketTypes"></app-table-enum-filter>
            </th><!--tipo-->
            <th *ngIf="showColumn('state')">
                <app-table-enum-filter
                    placeholder="Stato"
                    [field]="filterStringifier.clientTickets.stato.toString()"
                    [entries]="ticketStates"></app-table-enum-filter>
            </th><!--stato-->
            <th *ngIf="showColumn('provider')">
                <app-table-enum-filter
                    placeholder="Provider"
                    [field]="filterStringifier.contract.provider.toString()"
                    [entries]="providers"></app-table-enum-filter>
            </th><!--Provider-->
            <th *ngIf="showColumn('contract')">
                <app-table-string-filter [field]="filterStringifier.contract.loginName.toString()"
                                         inputWidth="130px">
                </app-table-string-filter>
            </th><!--loginname-->
            <th *ngIf="showColumn('line')"></th><!--linea-->
            <th *ngIf="showColumn('clientName')">
                <app-table-string-filter [field]="filterStringifier.client.ragioneSociale.toString()"
                                         inputWidth="120px">
                </app-table-string-filter>
            </th><!--ragione sociale-->
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr class="ticket-row">
            <td *ngIf="showColumn('unread')">
                <app-td-unread-messages [unreadMessages]="obj(item).unread"
                                        [unreadChatsMessages]="obj(item).unreadChats"
                                        [unreadProviderMessages]="obj(item).unreadProviders"
                                        [unreadClientMessages]="obj(item).unreadClients"
                ></app-td-unread-messages>
            </td>
            <td *ngIf="showColumn('flag')">
                <app-td-flags [flags]="obj(item).flags"></app-td-flags>
                <app-td-alarms [alarms]="obj(item).alarms"></app-td-alarms>
                <app-td-chats [chats]="obj(item).chats"></app-td-chats>
                <span *ngFor="let assignment of obj(item).assignments"
                      [pTooltip]="assignment.message"
                      tooltipPosition="top"
                      [style.color]="assignment.isClientAssignment ? 'blue' : 'red'"
                      class="info-icon">
                            ➧
                        </span>
                <span *ngFor="let disserviceRequest of obj(item).disserviceRequests"
                      pTooltip="{{disserviceRequest.annullato ? 'RDP ANNULLATA!' : ('RDP automatica ' + (disserviceRequest.scadenza | appDate)) }}"
                      tooltipPosition="top"
                      [style.text-decoration]="disserviceRequest.annullato ? 'line-through' : 'none'"
                      style="color: green"
                      class="info-icon">
                            ♠
                        </span>
                <span *ngFor="let clientTicket of obj(item).clientTickets">
                            <app-td-closures [closure]="clientTicket.ticketClosure"></app-td-closures>
                        </span>
                <span *ngFor="let closure of obj(item).ticketgroupcheckchiusura"
                      pTooltip="{{'Check Chiusura: ' + (closure.dataCheckChiusura | appDate)}}"
                      tooltipPosition="top"
                      style="color: green"
                      class="info-icon">
                            ♥
                        </span>

            </td>
            <td *ngIf="showColumn('createdAt')">{{ obj(item).created | appDate:'short' }}</td>
            <td *ngIf="showColumn('updatedAt')">{{ obj(item).lastUpdate | appDate:'short' }}</td>
            <td *ngIf="showColumn('lastClientMessageDate')">{{ obj(item).lastClientMessageAt | appDate:'short' }}</td>
            <td *ngIf="showColumn('user')">
                            <span *ngIf="!obj(item).assigned && !obj(item).ignora">
                                <p-message severity="warn" text="Non assegnato"></p-message>
                            </span>
                <span *ngIf="obj(item).assigned">
                                <app-user-label [code]="obj(item).userMft"></app-user-label>
                                |
                                <app-user-label [code]="obj(item).userNgi"></app-user-label>
                            </span>
            <td *ngIf="showColumn('clientTicket')">
                <app-td-ticket-group-link [ticketGroupId]="obj(item).id"
                                          [tickets]="obj(item).clientTickets"></app-td-ticket-group-link>
            </td>
            <td *ngIf="showColumn('providerTicket')">
                <app-td-ticket-group-link [ticketGroupId]="obj(item).id"
                                          [tickets]="obj(item).providerTickets"></app-td-ticket-group-link>
            </td>
            <td  *ngIf="showColumn('clientTitle')" [innerHTML]="obj(item).clientTickets?.[0]?.titolo | highlightWord:filterRequest?.clientTickets?.titolo"></td>
            <td *ngIf="showColumn('providerTitle')"
                [innerHTML]="obj(item).providerTickets?.[0]?.titoloNgi | highlightWord:filterRequest?.providerTickets?.titoloNgi"></td>
            <td *ngIf="showColumn('type')">{{ obj(item).clientTickets?.[0]?.type | ticketType }}</td>
            <td *ngIf="showColumn('state')">{{ obj(item).clientTickets?.[0]?.stato | ticketState }}</td>
            <td *ngIf="showColumn('provider')"><span *ngFor="let provider of distinctTicketProviders(obj(item))">{{ provider | providerType }}
                <br/></span></td>
            <td *ngIf="showColumn('contract')">
                <app-td-contract *ngFor="let contract of distinctTicketContracts(obj(item))"
                                 [client]="obj(item).client" [contract]="contract"
                                 [highlightText]="filterRequest?.loginNameFilter"
                                 [showFilterTicketListButton]="true" [showNewTicketButton]="true"
                ></app-td-contract>
            </td>
            <td *ngIf="showColumn('line')">
                <app-contract-colorized-line *ngFor="let contract of distinctTicketContracts(obj(item))"
                                             width="130px"
                                             [contract]="contract"></app-contract-colorized-line>
            </td>
            <td *ngIf="showColumn('clientName')">
                <app-client-header-info [client]="obj(item).client"
                                        [highlightText]="filterRequest?.client?.ragioneSociale"
                                        [showSearchTicket]="true"></app-client-header-info>
            </td>
        </tr>
    </ng-template>

</p-table>
