import {Component, Input} from '@angular/core';
import {TicketChat} from '../../../../models/tickets/ticket-chat';

@Component({
    selector: 'app-td-chats',
    template: `<i *ngIf="chats?.length > 0"
                  pTooltip="{{chats.length}} messaggi di chat"
                  class="fa fa-envelope info-icon">
    </i>`,
    styleUrls: ['./common-flag-styles.scss']
})
export class TdChatsComponent {
    @Input() chats: TicketChat[];
}
