import {Type} from 'class-transformer';
import {Provider} from '../../enums/provider';
import {ProviderTicketState, ProviderTicketSyncState, TicketState} from '../../enums/ticket-states';
import {Contract} from '../../contract';
import {Stringifier} from '../../../services/stringifier.service';

@Stringifier
export class ProviderTicket {
    // L'identificativo del ticket nel DB
    ticketNgiId: number;

    // Identificativo del ticket lato provider
    idNgi: string;

    // Identificativo del ticket group
    idGroup: number;
    assegnato: string;
    nostro?: number;
    user: string;
    titoloNgi: string;
    tipo: string;
    tipoId?: number;
    // stato: ProviderTicketState;
    stateSimplified: ProviderTicketState;


    @Type(() => Date)
    creato?: Date;
    intestatario: string;
    loginName: string;
    hlan: string;
    max: string;
    requestId: string;
    /**
     *  @deprecated preferibilmente utilizzare internalProvider. Se non fosse possibile vedere i commenti lato .NET e modificarli
     */
    entity: string;
    /**
     * indica se il ticket è interno (MFT) oppure si necessita di vedere il provider associato al Contratto (login) associato
     */
    internalProvider: boolean;
    ignora?: number;
    unread?: number;
    syncState?: ProviderTicketSyncState;

    @Type(() => Contract)
    contract: Contract;

    @Type(() => Date)
    ts?: Date;

    @Type(() => ProviderTicketMessage)
    messages?: ProviderTicketMessage[];

    get isNew() {
        return this.ticketNgiId === 0;
    }

    getStateItem(): TicketState {
        return TicketState.parse(this.stateSimplified);
    }

    static getStringifier(level: number): ProviderTicket | null {
        if (level < 0) {
            return null;
        }
        return {
            ticketNgiId: null,
            idNgi: null,
            idGroup: null,
            assegnato: null,
            nostro: null,
            user: null,
            titoloNgi: null,
            tipo: null,
            tipoId: null,
            stateSimplified: null,
            creato: null,
            intestatario: null,
            loginName: null,
            hlan: null,
            max: null,
            requestId: null,
            entity: null,
            internalProvider: null,
            ignora: null,
            unread: null,
            syncState: null,
            contract: Contract.getStringifier(level - 1),
            ts: null,
            messages: null,
        } as ProviderTicket;
    }
}

@Stringifier
export class ProviderTicketMessage {
    uid: number;
    codice: string;
    idTicketNgi: number | null;
    // obsoleteId: number | null; // obsoleto
    idNgi: string;

    @Type(() => Date)
    sent: Date;

    mittente: string;
    testo: string;

    @Type(() => Date)
    ts: Date;

    nGiorniAutoCheck: number | null;

    @Type(() => ProviderTicketAttachment)
    attachments: ProviderTicketAttachment[] = [];

    syncState: ProviderTicketSyncState;

    static getStringifier(level: number): ProviderTicketMessage | null {
        if (level < 0) {
            return null;
        }
        return {
            uid: null,
            codice: null,
            idTicketNgi: null,
            idNgi: null,
            sent: null,
            mittente: null,
            testo: null,
            ts: null,
            nGiorniAutoCheck: null,
            attachments: null,
            syncState: null,
        } as ProviderTicketMessage;
    }
}

@Stringifier
export class ProviderTicketAttachment {
    providerTicketAttachmentId: number;
    messageId: number;
    originalFilename: string;
    storagePath: string;
    @Type(() => Date)
    createdAt: Date;

    static getStringifier(level: number): ProviderTicketAttachment | null {
        if (level < 0) {
            return null;
        }
        return {
            providerTicketAttachmentId: null,
            messageId: null,
            originalFilename: null,
            storagePath: null,
            createdAt: null,
        } as ProviderTicketAttachment;
    }
}


export interface TicketSearchOption {
    label: string;
    code: string;
    defaultValue?: boolean;
}

export class ProviderTicketStateItem {
    code: string;
    description: string;
    closed: boolean;
}

export const MIN_TICKET_LENGTH: Partial<Record<Provider, number>> = {
    [Provider.AN]: 70,
};
