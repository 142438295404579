import {Pipe, PipeTransform} from '@angular/core';
import {TicketType} from '../models/responses/ticket-type';
import {Provider} from '../models/enums/provider';
import {ClientTicketState} from '../models/enums/ticket-states';

@Pipe({name: 'ticketCode'})
export class TicketCodePipe implements PipeTransform {

    transform(value: ClientTicketState | string, whenEmpty?: string): string {
        return TicketCodePipe.transform(value, whenEmpty);
    }

    static transform(value: string, whenEmpty?: string) {
        if (!value) {
            if (whenEmpty) {
                return whenEmpty;
            }
            return value;
        }
        if (value.startsWith('UANIA') && value.includes('-')) {
            return value.split('-')[0].toUpperCase();
        }
        return value;
    }
}
