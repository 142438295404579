import {BaseFilterRequest} from './base-filter-request';
import {FilterClientRequest} from './filter-client-request';
import {FString} from '../../services/api/filters/string-filter';
import {FilterContractRequest} from './filter-contract-request';
import {FEnum} from '../../services/api/filters/enum-filter';
import {BooleanFilter} from '../../services/api/filters/boolean-filter';
import {FInt, IntFilter} from '../../services/api/filters/int-filter';
import {FDate} from '../../services/api/filters/date-filter';
import {TicketType} from '../responses/ticket-type';
import {Stringifier} from '../../services/stringifier.service';

@Stringifier
export class FilterTicketRequest extends BaseFilterRequest {
    sequentialRequestId: number;
    client?: FilterClientRequest;
    clientTickets?: FilterClientTicketRequest;
    providerTickets?: FilterProviderTicketRequest;
    contract?: FilterContractRequest;
    owners?: FString;
    type?: TicketType;
    ignora?: BooleanFilter;
    internal?: BooleanFilter;
    unread?: IntFilter;
    ts?: FDate;
    created?: FDate;
    lastClientMessageAt?: FDate;
    lastUpdate?: FDate;

    providers?: string[];
    loginNameFilter?: string;

    inSync?: boolean;

    static getStringifier(level: number): FilterTicketRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            sequentialRequestId: null,
            client: FilterClientRequest.getStringifier(level - 1),
            clientTickets: FilterClientTicketRequest.getStringifier(level - 1),
            providerTickets: FilterProviderTicketRequest.getStringifier(level - 1),
            contract: FilterContractRequest.getStringifier(level - 1),
            owners: null,
            type: null,
            ignora: null,
            internal: null,
            unread: null,
            ts: null,
            lastClientMessageAt: null,
            created: null,
            lastUpdate: null,
            providers: null,
            loginNameFilter: null,
            inSync: null,
        } as FilterTicketRequest;
    }
}

@Stringifier
export class FilterClientTicketRequest extends BaseFilterRequest {

    id?: FInt;
    titolo?: FString;
    stato?: FEnum;

    static getStringifier(level: number): FilterClientTicketRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            id: null,
            titolo: null,
            stato: null,
        } as FilterClientTicketRequest;
    }
}

@Stringifier
export class FilterProviderTicketRequest extends BaseFilterRequest {

    idNgi?: string;
    titoloNgi?: FString;

    static getStringifier(level: number): FilterProviderTicketRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            idNgi: null,
            titoloNgi: null,
        } as FilterProviderTicketRequest;
    }
}
