import {Component, Input} from '@angular/core';
import {IntFilters} from '../../../../services/api/filters/int-filter';
import {SelectItem} from 'primeng/api';

@Component({
    selector: 'app-table-int-filter',
    templateUrl: './int-filter.component.html',
    styleUrls: ['./int-filter.component.scss']
})
export class IntFilterComponent {

    @Input() field: string;

    @Input() inputWidth = '200px';

    defaultMatch = IntFilters.Equals;

    matchOptions = [
        {icon: 'fa-solid fa-equals', label: 'Uguale', value: IntFilters.Equals},
        {icon: 'fa-solid fa-not-equals', label: 'Diverso', value: IntFilters.NotEquals},
        {icon: 'fa-solid fa-greater-than-equal', label: 'Maggiore o uguale', value: IntFilters.GreaterThan},
        {icon: 'fa-solid fa-less-than-equal', label: 'Minore o uguale', value: IntFilters.LessThan},
    ] as SelectItem[];

}
