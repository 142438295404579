import {Type} from 'class-transformer';
import {EasyCastReflectorDevice, IEasyCastReflectorDevice} from './easy-cast-reflector-device';
import {Stringifier} from '../../services/stringifier.service';
import {EasyCastUser, IEasyCastUser} from './easy-cast-user';

// tslint:disable-next-line:no-empty-interface
export interface IEasyCastLocation extends EasyCastLocation {
}

@Stringifier
export class EasyCastLocation {
    locationId: number;
    name: string;
    userId: number;
    @Type(() => EasyCastUser)
    user: IEasyCastUser;

    @Type(() => EasyCastReflectorDevice)
    reflectorDevices: IEasyCastReflectorDevice[];

    static getStringifier(level: number): EasyCastLocation | null {
        if (level < 0) {
            return null;
        }
        return {
            locationId: null,
            name: null,
            userId: null,
            user: EasyCastUser.getStringifier(level - 1),
            reflectorDevices: null,
        } as EasyCastLocation;
    }
}
