import {Component, Input, OnInit} from '@angular/core';
import {RestApiService} from '../../../../services/api';
import {ToastService} from '../../../../services/toast.service';
import {Title} from '@angular/platform-browser';
import {TicketGroupDto} from '../../../../models/responses/ticket-group-dto';
import {LazyLoadEvent} from 'primeng/api';
import {getFilterRequest} from '../../../../services/api/filters/filter-utils';
import {FilterClientTicketRequest, FilterTicketRequest} from '../../../../models/requests/filter-ticket-request';
import {RestApiError} from '../../../../services/api/rest-api-error';
import {ListedResponse} from '../../../../models/hydratator/listed-response';
import {ClientTicketState} from '../../../../models/enums/ticket-states';
import {FInt, IntFilters} from '../../../../services/api/filters/int-filter';
import {subHours} from 'date-fns';
import {EnumFilters, FEnum} from '../../../../services/api/filters/enum-filter';
import {RuleOperation} from '../../../../services/api/filters/filter';
import {FString, StringFilters} from '../../../../services/api/filters/string-filter';
import {Routing} from '../../../../models/routing/routing';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ReplaySubject} from 'rxjs';
import {CriticalTicketsOptions} from '../../../../models/tickets/critical-tickets-options';
import {CriticalTicketsMenuComponent} from './critical-tickets-menu/critical-tickets-menu.component';
import {MenuService} from '../../../../services/menu.service';

@UntilDestroy()
@Component({
    selector: 'app-critical-tickets-list',
    templateUrl: './critical-tickets-list.component.html',
    styleUrls: ['./critical-tickets-list.component.scss']
})
export class CriticalTicketsListComponent implements OnInit {

    @Input()
    asIcon = false;

    /**
     * I ticket sono in fase di caricamento
     */
    ticketsAreLoading = false;

    /**
     * Lista dei ticket caricati
     */
    tickets: ListedResponse<TicketGroupDto> = {dataList: [], count: 0};

    refresh$ = new ReplaySubject<{event: LazyLoadEvent, options: CriticalTicketsOptions}>();

    setIntervalId: any;

    constructor(private api: RestApiService,
                private toast: ToastService,
                private titleService: Title,
                private menu: MenuService,
                ) {
    }

    ngOnInit(): void {

        if (!this.asIcon) {
            this.menu.setMenu({menu: CriticalTicketsMenuComponent});
        }
        this.refresh$.pipe(
            untilDestroyed(this),
        ).subscribe(({event, options}) => {
            this.loadTickets(event, options);
            this.setRefreshInterval(options.minutesBeforeRefresh * 60);
        });

        if (!this.asIcon) {
            this.titleService.setTitle('Lista ticket - Phoenix');
        } else {
            this.loadOptions({});
        }
    }

    setRefreshInterval(seconds: number) {
        clearInterval(this.setIntervalId);
        this.setIntervalId = setInterval(() => {
            this.loadOptions({});
        }, seconds * 1000);
    }

    async loadOptions(event: LazyLoadEvent) {
        try {
            const options = await this.api.tickets.getCriticalTicketsOptions();
            this.refresh$.next({
                event,
                options: options.data
            });
        } catch (e) {
            console.log(e);
        }
    }

    async loadTickets(event: LazyLoadEvent, options: CriticalTicketsOptions) {
        this.ticketsAreLoading = true;
        try {
            const filterRequest = getFilterRequest<FilterTicketRequest>(event);
            this.handleOnlyTicketsWithClientSideNotAnswered(filterRequest);
            this.handleOwners(filterRequest, options.users);

            const result = await this.api.tickets.criticalTickets(
                subHours(new Date(), options.hoursWithoutResponse - 1 /* per conversione a locale da UTC */),
                filterRequest);
            this.tickets = result.data;
        } catch (e) {
            if (e instanceof RestApiError) {
                this.toast.error('Errore durante il caricamento della lista ticket', e.message);
            } else {
                throw e;
            }
        } finally {
            this.ticketsAreLoading = false;
        }
    }

    handleOnlyTicketsWithClientSideNotAnswered(request: FilterTicketRequest) {
        request.clientTickets = {
            id: {
                allowEmptyCollection: false,
                filters: [{
                    values: [0],
                    filterType: IntFilters.GreaterThan
                }]
            } as FInt,
            stato: {
                allowEmptyCollection: false,
                filters: [{
                    values: [ClientTicketState.ClosedByClient, ClientTicketState.Closed],
                    filterType: EnumFilters.NotIn
                }]
            } as FEnum
        } as FilterClientTicketRequest;
    }

    handleOwners(request: FilterTicketRequest, users: string[]) {
        request.owners = {
            ruleOperation: RuleOperation.And,
            filters: [
                ...(request.owners?.filters ?? []),
                {
                    filterType: StringFilters.Contains,
                    values: users
                }]
        } as FString;
    }

    gotToCriticalTicketsList() {
        window.open(Routing.tickets.criticalTickets(), '_blank');
    }
}
