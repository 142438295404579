import {Component, Input, OnInit} from '@angular/core';
import {Contract} from 'src/app/models/contract';
import {openMftwebUrl} from '../../../views/mftweb/mftweb/mftweb.component';
import {Routing} from '../../../models/routing/routing';
import {Router} from '@angular/router';
import {Client} from '../../../models/clients/client';

@Component({
    selector: 'app-contract-info-button',
    styles: [`
        .p-button-danger {
            cursor: help;
        }
    `],
    template: `
        <button
            pButton type="button" icon="fa fa-info"
            class="p-button-sm p-button-text p-0 m-0"
            [class.p-button-success]="client"
            [class.p-button-danger]="!client"
            [pTooltip]="getTooltip()"
            (click)="openContractInfo()"
        ></button>
    `
})
export class ContractInfoButtonComponent implements OnInit {

    @Input()
    contract: Contract;

    @Input()
    client: Client;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    openContractInfo() {
        const url = Routing.clients.byContractLoginName(this.client.clientId, this.contract.loginName);
        window.open(url, '_blank');
        // openMftwebUrl(`/Login/Login.php?id=${this.contract?.loginName.trim()}`);
    }

    getTooltip() {
        if (!this.client) {
            return 'Nessun cliente associato al contratto. Apri la scheda di un cliente per associare questo contratto tramite l\'apposita funzione';
        }
        return 'Apri la scheda relativa al contratto ' + this.contract.loginName;
    }

}
