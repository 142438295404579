import {FString} from '../../services/api/filters/string-filter';
import {BaseFilterRequest} from './base-filter-request';
import {FInt} from '../../services/api/filters/int-filter';
import {BooleanFilter} from '../../services/api/filters/boolean-filter';
import {FDate} from '../../services/api/filters/date-filter';
import {FEnum} from '../../services/api/filters/enum-filter';
import {Stringifier} from '../../services/stringifier.service';
import {FilterLoginClientRequest} from './filter-login-client.request';

@Stringifier
export class FilterClientRequest extends BaseFilterRequest {
    clientId?: FInt;
    ragioneSociale?: FString;
    email?: FString;
    allEmail?: FString;
    cf?: FString;
    username?: FString;
    password?: FString;
    comune?: FString;
    tipo?: FEnum;
    // genericText?: FString;
    state?: FString;
    dirig?: BooleanFilter;
    captive?: BooleanFilter;
    clientFlags?: FilterClientFlagsRequest;
    idAgenteZucchettiNavigation?: FilterAgenteZucchettiRequest;
    activeServicesCount?: FInt;
    obsoleteDate?: FDate;
    idZucchetti?: FString;
    contractClientAssociations?: FilterLoginClientRequest;
    showAlsoClosedContracts?: boolean;
    globalSearch?: string;
    sequentialRequestId?: number;
    showLoginTrees?: boolean;
    agentZone?: FilterAgentZoneRequest;

    // Se questo filtro e a true, per ogni cliente che rispetta la ricerca vengono ritornati solo i contratti che
    //  passano i filtri impostati sulla colonna contratti
    // Se invece è false, vengono ritornati tutti i contratti del cliente, indipendentemente dai filtri contratto impostati
    showNotMatchedContracts?: boolean;

    constructor() {
        super();
        this.showAlsoClosedContracts = false;
    }

    static getStringifier(level: number): FilterClientRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            clientId: null,
            ragioneSociale: null,
            email: null,
            allEmail: null,
            cf: null,
            username: null,
            password: null,
            comune: null,
            tipo: null,
            state: null,
            dirig: null,
            captive: null,
            clientFlags: FilterClientFlagsRequest.getStringifier(level - 1),
            idAgenteZucchettiNavigation: FilterAgenteZucchettiRequest.getStringifier(level - 1),
            activeServicesCount: null,
            obsoleteDate: null,
            idZucchetti: null,
            contractClientAssociations: FilterLoginClientRequest.getStringifier(level - 1),
            showAlsoClosedContracts: null,
            globalSearch: null,
            sequentialRequestId: null,
            showLoginTrees: null,
            agentZone: FilterAgentZoneRequest.getStringifier(level - 1),
            showNotMatchedContracts: null,
        } as FilterClientRequest;
    }
}

@Stringifier
export class FilterClientFlagsRequest extends BaseFilterRequest {
    clientFlagId?: FInt;

    static getStringifier(level: number): FilterClientFlagsRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            clientFlagId: null,
        } as FilterClientFlagsRequest;
    }
}

@Stringifier
export class FilterAgenteZucchettiRequest extends BaseFilterRequest {
    descrizione?: FString;

    static getStringifier(level: number): FilterAgenteZucchettiRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            descrizione: null,
        } as FilterAgenteZucchettiRequest;
    }
}

@Stringifier
export class FilterAgentZoneRequest extends BaseFilterRequest {
    zucchettiCode?: FString;
    description?: FString;
    vaidFromDate?: FDate;
    obsoleteFromDate?: FDate;

    static getStringifier(level: number): FilterAgentZoneRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            zucchettiCode: null,
            description: null,
            vaidFromDate: null,
            obsoleteFromDate: null,
        } as FilterAgentZoneRequest;
    }
}
