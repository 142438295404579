import {Component, Input, OnInit} from '@angular/core';
import {Routing} from '../../models/routing/routing';
import {Client} from '../../models/clients/client';
import {openMftwebUrl} from '../../views/mftweb/mftweb/mftweb.component';
import {RestApiService} from '../../services/api';
import {AclPermission} from '../../models/acl/acl-group';
import {FString} from '../../services/api/filters/string-filter';

@Component({
    selector: 'app-client-header-info',
    templateUrl: 'client-header-informations.component.html',
    styleUrls: ['client-header-informations.component.scss', '../tickets/tickets-list/td-content-components/common-flag-styles.scss']
})
export class ClientHeaderInformationsComponent implements OnInit {

    readonly AclPermission = AclPermission;
    private _client: Client = <Client>{};

    @Input() set client(client: Client) {
        this._client = client;
        if (this._client instanceof Client) {
            this.telephones = this._client.getPhones();
            this.mobilePhones = this._client.getMobilePhones();
            this.technicalPhones = this._client.getTechnicalPhones();
        }
    }

    get client(): Client {
        return this._client;
    }

    @Input() showSearchTicket = false;
    @Input() showTelephones = false;
    @Input() showAttributes = true;
    @Input() showAgent = false;
    @Input() showDeviceMap = true;
    @Input() alignCenter = true;
    @Input() showGenericTicketButton = false;
    @Input() highlightText: string | FString | (string | FString)[];
    @Input() showAccessLog = false;
    @Input() showButtonLabels = false;

    mobilePhones: string[] = [];
    telephones: string[] = [];
    technicalPhones: string[] = [];
    accessLogVisible = false;

    constructor(private api: RestApiService) {
    }

    ngOnInit() {
        this.loadZucchettiAgent();
    }

    async loadZucchettiAgent() {
        if (this.showAgent && this.client.idAgenteZucchetti) {
            this.client.idAgenteZucchettiNavigation = (await this.api.clients.getZucchettiAgent(this.client.clientId)).data;
        }
    }

    searchTicketsByName() {
        window.open(Routing.tickets.listByFiscalName(this.client.ragioneSociale), '_blank');
    }

    openAgentInfo() {
        openMftwebUrl(`/Agenti/Agente.php?id=${this.client.idAgenteZucchetti}`);
    }

    openGenericTicket() {
        window.open(Routing.tickets.new(this.client.clientId), '_blank');
    }

    openDeviceMap() {
        window.open(Routing.devicesMap.clientMap(this.client.clientId), '_blank');
    }

    openAccessLog() {
        this.accessLogVisible = true;
    }

}
