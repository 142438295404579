import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contract} from '../../../models/contract';
import {ConfirmationService} from 'primeng/api';
import {TicketGroup} from '../../../models/tickets/ticket-group';
import {SessionStorageService} from '../../../services/session-storage.service';
import {AclPermission} from '../../../models/acl/acl-group';
import {Routing} from '../../../models/routing/routing';
import {TicketCategory} from '../../../models/requests/ticket-form/create-ticket-request';
import {getMftwebUrl} from '../../../views/mftweb/mftweb/mftweb.component';
import {RestApiService} from '../../../services/api';
import {Client} from '../../../models/clients/client';
import {toastRestApiError} from '../../../services/api/rest-api-error';
import {ToastService} from '../../../services/toast.service';

@Component({
    selector: 'app-new-ticket-button',
    template: `
        <p-blockUI [blocked]="loading">
            <div class="flex align-items-center justify-content-center">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </p-blockUI>
        <button
            pButton type="button" [icon]="'fa ' + (type === TicketCategory.Technical ? 'fa-wrench' : 'fa-magnifying-glass-dollar')"
            class="p-button-sm p-button-success p-button-text p-0 m-0"
            [pTooltip]="type === TicketCategory.Technical ? 'Apri un nuovo ticket per la linea ' + contract.loginName : 'Apri una nuova richiesta di controllo commerciale'"
            (click)="openTicket()"
        ></button>
    `
})
export class NewTicketButtonComponent implements OnInit {

    @Input()
    client: Client;

    @Input()
    contract: Contract;

    @Input()
    ticketGroup?: TicketGroup;

    @Input()
    type: TicketCategory = TicketCategory.Technical;

    @Output()
    create = new EventEmitter<TicketGroup>();

    loading = false;

    TicketCategory = TicketCategory;

    constructor(private confirmDialog: ConfirmationService,
                private api: RestApiService,
                private toast: ToastService,
                private sessionStorage: SessionStorageService) {
    }

    ngOnInit(): void {
    }

    openTicket() {
        if (this.type === TicketCategory.Technical) {
            // rimuovo il popup per l'apertura di nuovo ticket come da richiesta di Oneta
            // this.confirmDialog.confirm({
            //     message: `Vuoi aprire una nuova bozza ticket per ${this.contract.loginName}?`,
            //     accept: () => {
            let url;
            url = this.sessionStorage.hasPermission(AclPermission.PhoenixTicketManagement)
                ? Routing.tickets.new(null, this.contract.loginName.trim()) // TODO usare l'id del contratto
                : getMftwebUrl(`/Ticket/Ticket.php?new=${this.contract?.loginName.trim()}`);
            window.open(url, '_blank');
            //     }
            // });
        } else {
            const toastTitle = 'Apertura controllo commerciale';
            this.confirmDialog.confirm({
                message: `Vuoi aprire un nuovo ticket per controllo commerciale?`,
                accept: () => {
                    this.loading = true;
                    this.api.clients.newCommercialEvaluation(
                        this.client.clientId,
                        this.contract?.contractId,
                        this.ticketGroup?.id
                    ).then(response => {
                        this.toast.success(toastTitle, 'Ticket creato con successo');
                        this.create.emit(response.data);
                    }).catch(error => {
                        if (!toastRestApiError(this.toast, toastTitle, error)) {
                            this.toast.error(toastTitle, 'Errore durante l\'apertura del controllo commerciale');
                        }
                    }).finally(() => this.loading = false);
                }
            });

        }
    }
}
