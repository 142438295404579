<div *ngIf="asIcon && tickets.count > 0">
    <app-action-button
        type="alert"
        [textButton]="true"
        pTooltip="Ticket senza risposta da almeno {{ (refresh$ | async)?.options.hoursWithoutResponse }} ore"
        (click)="gotToCriticalTicketsList()"
    ></app-action-button>
</div>

<p-panel *ngIf="!asIcon">
    <ng-template pTemplate="header">
        Ticket senza risposta da almeno {{ (refresh$ | async)?.options.hoursWithoutResponse }} ore
    </ng-template>

    <ng-template pTemplate="icons">
        <app-counter-badge [count]="tickets.count" entityPlural="ticket" style="float: right"
                           entitySingular="ticket"></app-counter-badge>
    </ng-template>

    <ng-template pTemplate="content">

        <app-tickets-table [tickets]="tickets"
                           [loading]="ticketsAreLoading"
                           [showFilters]="false"
                           [pagination]="false"
                           [columnsToShow]="[
                            'createdAt',
                            'user',
                            'clientTicket',
                            'clientTitle',
                            'type',
                            'state',
                            'provider',
                            'contract',
                            'line',
                            'clientName',
                            'lastClientMessageDate'
                           ]"
                           (triggerLazyLoad)="loadOptions($event)"
                           ></app-tickets-table>
    </ng-template>
</p-panel>
