import {Type} from 'class-transformer';
import {Stringifier} from 'src/app/services/stringifier.service';
import {CustomFieldType, ICustomFieldType} from './CustomFieldType';
import {INetworkInterfaceTemplate, NetworkInterfaceTemplate} from './NetworkInterfaceTemplate';

// tslint:disable-next-line:no-empty-interface
export interface INetworkInterfaceTemplateCustomFieldType extends NetworkInterfaceTemplateCustomFieldType {
}

@Stringifier
export class NetworkInterfaceTemplateCustomFieldType {
    networkInterfaceTemplateCustomFieldTypeId: number;
    networkInterfaceTemplateId: number;
    customFieldTypeId: number;
    isRequired: boolean;
    order: number;

    @Type(() => CustomFieldType)
    customFieldType?: ICustomFieldType;
    @Type(() => NetworkInterfaceTemplate)
    networkInterfaceTemplate?: INetworkInterfaceTemplate;

    static getStringifier(level: number): NetworkInterfaceTemplateCustomFieldType | null {
        if (level < 0) {
            return null;
        }
        return {
            networkInterfaceTemplateCustomFieldTypeId: null,
            networkInterfaceTemplateId: null,
            customFieldTypeId: null,
            isRequired: null,
            order: null,
            customFieldType: CustomFieldType.getStringifier(level - 1),
            networkInterfaceTemplate: NetworkInterfaceTemplate.getStringifier(level - 1),
        } as NetworkInterfaceTemplateCustomFieldType;
    }
}
