import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {DeviceTemplateCustomFieldType, IDeviceTemplateCustomFieldType} from './DeviceTemplateCustomFieldType';
import {DeviceTemplateNetworkInterfaceTemplate, IDeviceTemplateNetworkInterfaceTemplate} from './DeviceTemplateNetworkInterfaceTemplate';

// tslint:disable-next-line:no-empty-interface
export interface IDeviceTemplate extends DeviceTemplate {
}

@Stringifier
export class DeviceTemplate {
    deviceTemplateId: number;
    name: string;
    type: string;
    iconId: number;

    @Type(() => DeviceTemplateNetworkInterfaceTemplate)
    deviceTemplateNetworkInterfaceTemplates: IDeviceTemplateNetworkInterfaceTemplate[];
    @Type(() => DeviceTemplateCustomFieldType)
    deviceTemplateCustomFieldTypes: IDeviceTemplateCustomFieldType[];

    static getStringifier(level: number): DeviceTemplate | null {
        if (level < 0) {
            return null;
        }
        return {
            deviceTemplateId: null,
            name: null,
            type: null,
            iconId: null,
            deviceTemplateNetworkInterfaceTemplates: null,
            deviceTemplateCustomFieldTypes: null,
        } as DeviceTemplate;
    }
}



