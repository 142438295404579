<app-action-button
    *appAcl="AclPermission.SetCriticalTicketsOptions"
    type="edit"
    label="Modifica impostazioni lista ticket senza risposta"
    (click)="editCriticalTicketsListSettings()"
></app-action-button>

<p-dialog header="Configurazione opzioni lista ticket senza risposta"
          [(visible)]="showEditOptions"
          [modal]="true"
>
    <div>
        <label class="mr-5">Utenti assegnatari dei ticket</label>
        <p-multiSelect
            appendTo="body"
            [options]="usersList"
            [(ngModel)]="options.users"
            [group]="true"
            optionLabel="label"
            optionValue="key"
            optionGroupLabel="group"
            optionGroupChildren="entries"
            maxSelectedLabels="3"
            [showHeader]="true"
        >
            <ng-template pTemplate="footer">
                <div class="flex align-items-center p-2 mt-3">
                    <p-inputSwitch [ngModel]="showInactiveUsers"
                                   (ngModelChange)="loadUsersList($event)"
                    ></p-inputSwitch>
                    <span class="ml-2">Mostra utenti inattivi</span>
                </div>
            </ng-template>

        </p-multiSelect>
    </div>
    <div class="mt-3">
        <app-input type="number"
                   label="Tempo senza risposta (in ore)"
                   [(model)]="options.hoursWithoutResponse"
                   [showLength]="false"
                   [minValue]="1"
        ></app-input>
    </div>
    <div class="mt-3">
        <app-input type="number"
                   label="Frequenza aggiornamento (in minuti)"
                   [(model)]="options.minutesBeforeRefresh"
                   [showLength]="false"
                   [minValue]="1"
        ></app-input>
    </div>

    <div class="flex justify-content-end">
        <app-action-button type="save"
                           label="Salva"
                           (click)="save()"></app-action-button>
    </div>
</p-dialog>
