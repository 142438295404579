import {Component, Input} from '@angular/core';
import {TicketClosure} from '../../../../models/tickets/ticket-closure';

@Component({
    selector: 'app-td-closures',
    template: `<span *ngIf="closure"
                     pTooltip="{{!closure.annullata
                               ?  'Chiusura ' + (closure.tipo == 'COM' ? 'COM ' : 'TEC ') + (closure.chiusura | appDate)
                               : (closure.tipo == 'COM' ? 'COM' : 'TEC') + ' ANNULLATA'}}"
                     tooltipPosition="top"
                     style="color: red"
                     [class.strike-through]="closure.annullata"
                     class="info-icon">
                                ♥
                            </span>`,
    styleUrls: ['./common-flag-styles.scss']
})
export class TdClosuresComponent {
    @Input() closure: TicketClosure;
}
