import {Pipe, PipeTransform} from '@angular/core';
import {FString} from '../services/api/filters/string-filter';

// Genera tutte le combinazione di stringhe, concatenabili solo se consecutive
//  es: ["a","b","c","d"] => ["a b c d", "a b c", "b c d", "a b", "b c", "c d", "a", "b", "c", "d"]
//  In questo modo le parole consecutive vengono evidenziate insieme
function generateCombinations(arr: string[]): string[] {
    const combinations: string[] = [];

    for (let i = arr.length; i >= 1; i--) {
        for (let j = 0; j <= arr.length - i; j++) {
            combinations.push(arr.slice(j, j + i).join(' '));
        }
    }

    return combinations;
}

@Pipe({
    name: 'highlightWord'
})
export class HighlightWordPipe implements PipeTransform {

    transform(value: string, filter: string | FString | (string | FString)[], ignoreCase = true, classHighlight = 'highlighted-text'): string {
        if (filter === null || filter === undefined || filter === '') {
            return value;
        }

        let searchingText = '';

        if (Array.isArray(filter)) {
            for (const partialFilter of filter) {
                if (!partialFilter) {
                    continue;
                }
                if (typeof partialFilter === 'string') {
                    // string
                    searchingText += partialFilter + ' ';
                } else {
                    // FString
                    searchingText += partialFilter.filters.map(f => f.values.join(' ')).join(' ') + ' ';
                }
            }
            searchingText = searchingText.trim();
        } else if (typeof filter === 'string') {
            // se filter è una stringa
            searchingText = filter;
        } else {
            // se filter è un oggetto FString
            searchingText = filter.filters.map(f => f.values.join(' ')).join(' ');
        }

        const wordList = searchingText.split(' ').filter(word => word.length > 0);
        const escapedWordList = wordList.map(word => word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
        const regex = new RegExp(`(${generateCombinations(escapedWordList).join('|')})`, ignoreCase ? 'ig' : 'g');

        return value.replace(regex, `<span class="${classHighlight}">$1</span>`);
    }
}
