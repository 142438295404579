import {Component, Input} from '@angular/core';
import {TicketAlarm} from '../../../../models/tickets/ticket-alarm';

@Component({
    selector: 'app-td-alarms',
    template: `<i *ngFor="let alarm of alarms"
                  pTooltip="{{alarm.alData | appDate}}: {{alarm.alMsg}}"
                  style="color: yellow; -webkit-text-stroke: 1px #8d4a14;"
                  class="fa fa-bell ticket-icon info-icon">
    </i>`,
    styleUrls: ['./common-flag-styles.scss']
})
export class TdAlarmsComponent {
    @Input() alarms: TicketAlarm[];
}
