import {Filter, FilterRule} from './filter';

export enum StringFilters {
    Contains = 'contains',
    NotContains = 'notContains',
    Equals = 'equals',
    NotEquals = 'notEquals',
    StartsWith = 'startsWith',
    EndsWith = 'endsWith'
}

export type StringFilter = Filter<string, StringFilters>;

export class FString extends FilterRule<StringFilter> {
    constructor(value?: string, filterType?: StringFilters) {
        super();
        if (value) {
            this.filters.push({
                filterType: filterType ?? StringFilters.Contains,
                values: [value]
            });
        }
    }
}
