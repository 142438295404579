import {Component, Input, OnInit} from '@angular/core';
import {SessionStorageService} from '../../../services/session-storage.service';
import {AclPermission} from '../../../models/acl/acl-group';
import {Routing} from '../../../models/routing/routing';
import {getMftwebUrl} from '../../../views/mftweb/mftweb/mftweb.component';

@Component({
    selector: 'app-ticket-group-link',
    template: `
        <a class="p-button-link p-0 mr-1 ml-1"
           pButton
           target="_blank"
           [href]="mftWebTicket(ticketGroupId)">{{label ? label : ticketGroupId}}</a>
    `,
})
export class TicketGroupLinkComponent implements OnInit {

    @Input()
    ticketGroupId: number;

    @Input()
    label: string | number;

    constructor(private sessionService: SessionStorageService) {
    }

    ngOnInit(): void {
    }

    mftWebTicket(idGroup: number) {
        return this.sessionService.hasPermission(AclPermission.PhoenixTicketManagement)
            ? Routing.tickets.byIdGroup(idGroup)
            : getMftwebUrl(`/Ticket/Ticket.php?idGroup=${idGroup}`);
    }
}
