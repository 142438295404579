import {RemoteDeviceType} from './remote-device-type';
import {Client} from './clients/client';
import {RemoteDeviceLog} from './remote-device-log';
import {Type} from 'class-transformer';
import {utcDate} from '../utils/date-utils';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class RemoteDevice {

    deviceId: number;
    name: string;
    telephone: string;
    emnifyId: number;
    emnifySimId: number;

    clientId?: number;
    client?: Client;

    masterDeviceId?: number;
    masterDevice?: RemoteDevice;

    deviceTypeId?: number;
    deviceType: RemoteDeviceType;

    @Type(() => RemoteDeviceLog)
    lastStatus?: RemoteDeviceLog;
    @Type(() => RemoteDeviceLog)
    lastCommand?: RemoteDeviceLog;

    hasScheduledCommands = false;

    isMaster: boolean;

    @Type(() => Date)
    lastConnectionUpdate: Date;

    enabled: boolean;

    /**
     * Indica l'ultima volta in cui il client ha aggiornato le informazioni del device (ha richiamato le API di Atlantis).
     * Questo valore non è mappato lato API, ma viene usato solo dal client.
     */
    @Type(() => Date)
    lastClientSynchronization: Date = utcDate();

    static getStringifier(level: number): RemoteDevice | null {
        if (level < 0) {
            return null;
        }
        return {
            deviceId: null,
            name: null,
            telephone: null,
            emnifyId: null,
            emnifySimId: null,
            clientId: null,
            client: Client.getStringifier(level - 1),
            masterDeviceId: null,
            masterDevice: RemoteDevice.getStringifier(level - 1),
            deviceTypeId: null,
            deviceType: RemoteDeviceType.getStringifier(level - 1),
            lastStatus: RemoteDeviceLog.getStringifier(level - 1),
            lastCommand: RemoteDeviceLog.getStringifier(level - 1),
            hasScheduledCommands: null,
            isMaster: null,
            lastConnectionUpdate: null,
            enabled: null,
            lastClientSynchronization: null,
        } as RemoteDevice;
    }
}
