import {BooleanFilters} from './boolean-filter';

export enum RuleOperation {
    Or = 'or',
    And = 'and'
}

export class FilterRule<T extends Filter<unknown, unknown>> {
    ruleOperation: RuleOperation;
    filters: T[];
    allowEmptyCollection?: boolean;

    constructor() {
        this.filters = [];
        this.allowEmptyCollection = true;
        this.ruleOperation = RuleOperation.And;
    }
}

export interface Filter<T, F> {
    filterType: F;
    values: T[];
}
