<p-columnFilter type="numeric"
                [useGrouping]="false"
                [field]="field"
                [showClearButton]="false"
                [matchModeOptions]="matchOptions"
                [matchMode]="defaultMatch">
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <app-input [model]="value" (modelChange)="filter($event)"
                   [inputWidth]="inputWidth"
                   [showLength]="false" [debounceDelay]="500"
        ></app-input>
    </ng-template>
</p-columnFilter>
