import {RemoteDeviceCommand} from './remote-device-command';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class RemoteDeviceType {
    deviceTypeId?: number;
    name: string;

    commands: RemoteDeviceCommand[];

    static getStringifier(level: number): RemoteDeviceType | null {
        if (level < 0) {
            return null;
        }
        return {
            deviceTypeId: null,
            name: null,
            commands: null,
        } as RemoteDeviceType;
    }
}
