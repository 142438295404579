import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {INetworkInterface, NetworkInterface} from './NetworkInterface';

// tslint:disable-next-line:no-empty-interface
export interface IConnectedNetworkInterface extends ConnectedNetworkInterface {
}

@Stringifier
export class ConnectedNetworkInterface {
    connectedNetworkInterfaceId: number;

    networkInterfaceStartId: number;
    @Type(() => NetworkInterface)
    networkInterfaceStart: INetworkInterface;

    networkInterfaceEndId: number;
    @Type(() => NetworkInterface)
    networkInterfaceEnd: INetworkInterface;

    otherEnd(networkInterface: NetworkInterface) {
        if (this.networkInterfaceStartId === networkInterface.networkInterfaceId) {
            return this.networkInterfaceEnd;
        }
        if (this.networkInterfaceEndId === networkInterface.networkInterfaceId) {
            return this.networkInterfaceStart;
        }
        return null;
    }

    static computeUniqness(networkInterface: NetworkInterface, otherEnd: NetworkInterface) {
        console.log({networkInterface, otherEnd});
        if (networkInterface.networkInterfaceId < otherEnd.networkInterfaceId) {
            return `${networkInterface.networkInterfaceId}-${otherEnd.networkInterfaceId}`;
        }
        return `${otherEnd.networkInterfaceId}-${networkInterface.networkInterfaceId}`;
    }

    static getStringifier(level: number): ConnectedNetworkInterface | null {
        if (level < 0) {
            return null;
        }
        return {
            connectedNetworkInterfaceId: null,
            networkInterfaceStartId: null,
            networkInterfaceStart: NetworkInterface.getStringifier(level - 1),
            networkInterfaceEndId: null,
            networkInterfaceEnd: NetworkInterface.getStringifier(level - 1),
        } as ConnectedNetworkInterface;
    }
}
