import {Filter, FilterRule} from './filter';
import {StringFilter, StringFilters} from './string-filter';

export enum BooleanFilters {
    Equals = 'equals',
}

export type BooleanFilter = Filter<boolean, BooleanFilters>;

export class FBoolean extends FilterRule<BooleanFilter> {
    constructor(value?: boolean, filterType?: BooleanFilters) {
        super();
        if (value !== undefined) {
            this.filters.push({
                filterType: filterType ?? BooleanFilters.Equals,
                values: [value]
            });
        }
    }
}
