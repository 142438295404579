import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'pascalCase'})
export class PascalCasePipe implements PipeTransform {

    transform(word: any): string {
        if (!word) {
            return word;
        }
        return word.toString()
            .split('.')
            .filter(x => !!x)
            .map(x => x[0].toUpperCase() + x.substr(1) ?? '')
            .join('.');
    }
}
