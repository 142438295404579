import {Component, Input} from '@angular/core';
import {Contract} from '../../../../models/contract';
import {StringifierService} from '../../../../services/stringifier.service';
import {TicketGroup} from '../../../../models/tickets/ticket-group';
import {Client} from '../../../../models/clients/client';
import {TicketCategory} from 'src/app/models/requests/ticket-form/create-ticket-request';
import {FString} from '../../../../services/api/filters/string-filter';

@Component({
    selector: 'app-td-contract', // TODO: rinominare
    template: `
        <div *ngIf="contract; else genericAssistance">
            <span [innerHTML]="contract?.loginName | highlightWord:highlightText"></span>
            <span *ngIf="showEasyName && contract.easyName" class="easyName"
                  [innerHTML]="contract.easyName | highlightWord:highlightText"></span>

            <app-contract-status-icon [contract]="contract"></app-contract-status-icon>

            <app-search-tickets-button *ngIf="showFilterTicketListButton && contract"
                                       [field]="ticketGroupStringifier.contract.loginName"
                                       [value]="contract?.loginName"
                                       [pTooltip]="'Mostra tutti i ticket della linea ' + contract?.loginName"
            ></app-search-tickets-button>
            <app-new-ticket-button *ngIf="showNewTicketButton && contract"
                                   [client]="client"
                                   [contract]="contract"></app-new-ticket-button>
            <app-new-ticket-button *ngIf="showCommercialCheckButton && contract"
                                   [client]="client" [type]="TicketCategory.Commercial"
                                   [contract]="contract"></app-new-ticket-button>
            <app-contract-info-button *ngIf="showInfoButton" [contract]="contract" [client]="client"></app-contract-info-button>
        </div>
        <ng-template #genericAssistance>
            Assistenza generica
        </ng-template>`,
    styleUrls: ['./common-flag-styles.scss', './td-contract.component.scss']
})
export class TdContractComponent {
    @Input() client: Client;
    @Input() contract?: Contract;
    @Input() showFilterTicketListButton = false;
    @Input() showNewTicketButton = false;
    @Input() showCommercialCheckButton = false;
    @Input() showEasyName = false;
    @Input() showInfoButton = false;
    @Input() highlightText: string | FString | (string | FString)[];

    ticketGroupStringifier: TicketGroup;
    TicketCategory = TicketCategory;

    constructor(private stringifier: StringifierService) {
        this.ticketGroupStringifier = stringifier.getStringifier(TicketGroup, 2);
    }

}


