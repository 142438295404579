<p-panel>
    <ng-template pTemplate="header">
        <div class="flex">
            <p-selectButton
                class="mr-3 inline-block p-button-help"
                [options]="archivedTicketOptions"
                [(ngModel)]="selectedArchivedOptions" optionLabel="label" optionValue="code"
                (onChange)="triggerLoadTickets()"
            ></p-selectButton>

            <div *ngFor="let option of options" class="mr-3 flex align-items-center">
                <span class="ml-2 mr-2">{{ option.label }}</span>
                <p-inputSwitch
                    [(ngModel)]="option.defaultValue"
                    (onChange)="triggerLoadTickets()"
                ></p-inputSwitch>
            </div>

            <p-selectButton class="ml-3 inline-block p-button-help"
                            [options]="ticketsInSyncOptions"
                            [(ngModel)]="selectedTicketsInSyncOption" optionLabel="label" optionValue="code"
                            (onChange)="triggerLoadTickets()"
                            *appAcl="AclPermission.EditSyncStateProviderTickets"
            ></p-selectButton>
        </div>
    </ng-template>

    <ng-template pTemplate="icons">
        <app-counter-badge [count]="tickets.count" entityPlural="ticket" style="float: right"
                           entitySingular="ticket"></app-counter-badge>
    </ng-template>

    <ng-template pTemplate="content">

        <app-tickets-table #ticketTable
            [tickets]="tickets"
            [loading]="ticketsAreLoading"
            [showFilters]="true"
            [pagination]="true"
            [columnsToShow]="columnsToShow"
            [filterRequest]="filterRequest"
            (triggerLazyLoad)="triggerLoadTickets($event)"
        ></app-tickets-table>

    </ng-template>
</p-panel>
