import {Contract} from './contract';
import {Client, IClient} from './clients/client';
import {Type} from 'class-transformer';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class ContractClient {
    loginClientId?: number;

    loginContractId?: number;

    loginName?: string;

    @Type(() => Contract)
    contract?: Contract;

    clientId?: number;

    @Type(() => Date)
    ts?: Date;

    // Attenzione: dato ridondante a DB e non aggiornato!
    // stato?: string;

    @Type(() => Date)
    statodal?: Date;

    @Type(() => Client)
    client?: IClient;

    static getStringifier(level: number): ContractClient | null {
        if (level < 0) {
            return null;
        }
        return {
            loginClientId: null,
            loginContractId: null,
            loginName: null,
            contract: Contract.getStringifier(level - 1),
            clientId: null,
            ts: null,
            statodal: null,
            client: Client.getStringifier(level - 1),
        } as ContractClient;
    }
}

