import {BaseFilterRequest} from '../base-filter-request';
import {FString} from '../../../services/api/filters/string-filter';
import {Stringifier} from '../../../services/stringifier.service';

@Stringifier
export class FilterAclGroupsRequest extends BaseFilterRequest {
    name: FString;
    description: FString;
    users: FilterUserAclGroupRequest;
    permissionName: FString;

    static getStringifier(level: number): FilterAclGroupsRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            name: null,
            description: null,
            users: FilterUserAclGroupRequest.getStringifier(level - 1),
            permissionName: null,
        } as FilterAclGroupsRequest;
    }
}

@Stringifier
export class FilterUserAclGroupRequest extends BaseFilterRequest {
    user: FilterUserRequest;

    static getStringifier(level: number): FilterUserAclGroupRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            user: FilterUserRequest.getStringifier(level - 1),
        } as FilterUserAclGroupRequest;
    }
}

@Stringifier
export class FilterUserRequest extends BaseFilterRequest {
    /**
     * Esegue il filtro per codice/nome/cognome
     */
    codice: FString;

    static getStringifier(level: number): FilterUserRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            codice: null,
        } as FilterUserRequest;
    }
}
