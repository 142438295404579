import {BaseFilterRequest} from './base-filter-request';
import {Stringifier} from '../../services/stringifier.service';
import {FInt} from '../../services/api/filters/int-filter';
import {FString} from '../../services/api/filters/string-filter';
import {FEnum} from '../../services/api/filters/enum-filter';
import {FDate} from '../../services/api/filters/date-filter';
import {FilterTicketRequest} from './filter-ticket-request';

@Stringifier
export class TicketContentFilterRequest extends BaseFilterRequest {
    sequentialRequestId: number;
    contentSearch: string;

    ticketGroupId: FInt;
    ticketId: FString;
    loginName: FString;
    ticketGroup: FilterTicketRequest;
    side: FEnum;
    title: FString;
    text: FString;
    date: FDate;

    static getStringifier(level: number): TicketContentFilterRequest | null {
        if (level < 0) {
            return null;
        }
        return {
            sequentialRequestId: null,
            contentSearch: null,
            ticketGroupId: null,
            ticketId: null,
            loginName: null,
            ticketGroup: FilterTicketRequest.getStringifier(level - 1),
            side: null,
            title: null,
            text: null,
            date: null,
        } as TicketContentFilterRequest;
    }
}
