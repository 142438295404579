import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    Input,
    QueryList,
    TemplateRef
} from '@angular/core';
import {PrimeTemplate} from 'primeng/api';

@Component({
    selector: 'app-table-enum-filter',
    templateUrl: './enum-filter.component.html',
    styleUrls: ['./enum-filter.component.scss']
})
export class EnumFilterComponent implements AfterContentInit {
    @Input() field: string;
    @Input() entries: EnumFilterEntry[] | EnumFilterEntryGroup[] = [];
    @Input() placeholder = 'Placeholder';
    @Input() inputWidth = '200px';

    footerTemplate: TemplateRef<any>;

    @ContentChildren(PrimeTemplate) templates: QueryList<any>;

    get grouped() {
        return this.entries.length > 0 && isGrouped(this.entries[0]);
    }

    ngAfterContentInit() {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'footer':
                    this.footerTemplate = item.template;
                    break;
            }
        });
    }
}

export type EnumFilterKeyType = string | number | boolean;

export interface EnumFilterEntry {
    key: EnumFilterKeyType;
    label: string;
}

export interface EnumFilterEntryGroup {
    group: string;
    entries: EnumFilterEntry[];
}

function isGrouped(obj: EnumFilterEntry | EnumFilterEntryGroup): obj is EnumFilterEntryGroup {
    return (<EnumFilterEntryGroup>obj).group !== undefined;
}
