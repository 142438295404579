import {Stringifier} from '../services/stringifier.service';
import {Type} from 'class-transformer';

@Stringifier
export class ScheduledMaintenance {
    idInt: number;
    @Type(() => Date)
    dataInt: Date;
    messageInt: string;
    msgId: number;

    static getStringifier(level: number): ScheduledMaintenance | null {
        if (level < 0) {
            return null;
        }
        return {
            idInt: null,
            dataInt: null,
            messageInt: null,
            msgId: null,
        } as ScheduledMaintenance;
    }
}

@Stringifier
export class MaintenanceAlert {

    idInt: number;
    login: string;
    idTicket: number;

    @Type(() => ScheduledMaintenance)
    maintenance: ScheduledMaintenance;

    static getStringifier(level: number): MaintenanceAlert | null {
        if (level < 0) {
            return null;
        }
        return {
            idInt: null,
            login: null,
            idTicket: null,
            maintenance: ScheduledMaintenance.getStringifier(level - 1),
        } as MaintenanceAlert;
    }
}

