import {Stringifier} from '../../services/stringifier.service';

@Stringifier
export class MessageTemplate {

    id?: number;
    title: string;
    message: string;
    type: MessageTemplateType;
    usageFrequency: number;

    static getStringifier(level: number): MessageTemplate | null {
        if (level < 0) {
            return null;
        }
        return {
            id: null,
            title: null,
            message: null,
            type: null,
            usageFrequency: null,
        } as MessageTemplate;
    }
}

export enum MessageTemplateType {
    Client = 0,
    Provider = 1
}
