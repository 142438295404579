import {Pipe, PipeTransform} from '@angular/core';
import {Provider} from '../models/enums/provider';

@Pipe({name: 'providerType'})
export class ProviderPipe implements PipeTransform {

    transform(value: Provider | string): string {
        return ProviderPipe.transform(value);
    }

    static transform(value: Provider | string) {
        switch (value) {
            case Provider.AN:
                return 'BeActive (AN)';
            case Provider.MYWIC:
                return 'MyWic';
            case Provider.EOLO:
                return 'EOLO';
            case Provider.EOLOWH:
                return 'EOLOWH';
            case Provider.MFT:
                return 'MFT';
            case Provider.UANIA:
                return 'UANIA';
            case Provider.OPNET:
                return 'OPNET';
            case Provider.EFAX:
                return 'EFAX';
            case Provider.INTRED:
                return 'INTRED';
            case Provider.STARLINK:
                return 'STARLINK';
            case Provider.NEXIS:
                return 'NEXIS';
            case Provider.EasyCast:
                return 'easyCAST';
            default:
                return '';
        }
    }
}
