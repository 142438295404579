import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {EasyCastLicense} from './easy-cast-license';
import {EasyCastLocation, IEasyCastLocation} from './easy-cast-location';
import {PlaybackDevice} from './easy-cast-playback-device';

// tslint:disable-next-line:no-empty-interface
export interface IEasyCastReflectorDevice extends EasyCastReflectorDevice {
}

@Stringifier
export class EasyCastReflectorDevice {
    reflectorDeviceId: number;
    name: string;
    macAddress: string;
    version: string;
    internetInterfaceIpAddress: string;
    clientInterfaceIpAddress: string;
    clientApiInterfaceIpAddress: string;
    clientInterfaceTag?: number;
    playbackInterfaceIpAddress: string;
    playbackApiInterfaceIpAddress: string;
    playbackInterfaceTag?: number;
    registered: boolean;
    primaryDns = '8.8.8.8';
    secondaryDns = '1.1.1.1';
    dhcpRangeStart: string;
    dhcpRangeEnd: string;
    dhcpSubnetMask = '255.255.255.0';
    active: boolean;
    alive: boolean;
    ssidName: string;
    ssidPassword: string;

    @Type(() => Date)
    lastDetectedAliveAt: Date;

    @Type(() => Date)
    nextAutomaticCheckOut: Date;

    autoCheckOutEnabled: boolean;

    @Type(() => Date)
    startedReflectorRegistrationAt: Date;

    locationId: number;

    @Type(() => EasyCastLocation)
    location: IEasyCastLocation;

    @Type(() => EasyCastLicense)
    licenses: EasyCastLicense[];

    @Type(() => PlaybackDevice)
    playbackDevices: PlaybackDevice[];

    @Type(() => Date)
    hiFrequencyWifiMonitoringStartsAt: Date;

    @Type(() => Date)
    hiFrequencyWifiMonitoringEndsAt: Date;

    // defaultBackgroundAppId: number;
    // @Type(() => BackgroundApp)
    // defaultBackgroundApp: IBackgroundApp;
    //
    // errorBackgroundAppId: number;
    // @Type(() => BackgroundApp)
    // errorBackgroundApp: IBackgroundApp;
    //
    // @Type(() => BackgroundApp)
    // backgroundApps: IBackgroundApp[];
    static getStringifier(level: number): EasyCastReflectorDevice | null {
        if (level < 0) {
            return null;
        }
        return {
            reflectorDeviceId: null,
            name: null,
            macAddress: null,
            version: null,
            internetInterfaceIpAddress: null,
            clientInterfaceIpAddress: null,
            clientApiInterfaceIpAddress: null,
            clientInterfaceTag: null,
            playbackInterfaceIpAddress: null,
            playbackApiInterfaceIpAddress: null,
            playbackInterfaceTag: null,
            registered: null,
            primaryDns: null,
            secondaryDns: null,
            dhcpRangeStart: null,
            dhcpRangeEnd: null,
            dhcpSubnetMask: null,
            active: null,
            alive: null,
            ssidName: null,
            ssidPassword: null,
            lastDetectedAliveAt: null,
            nextAutomaticCheckOut: null,
            autoCheckOutEnabled: null,
            startedReflectorRegistrationAt: null,
            locationId: null,
            location: EasyCastLocation.getStringifier(level - 1),
            licenses: null,
            playbackDevices: null,
            hiFrequencyWifiMonitoringStartsAt: null,
            hiFrequencyWifiMonitoringEndsAt: null,
        } as EasyCastReflectorDevice;
    }
}
