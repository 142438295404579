import {RemoteDevice} from './remote-device';
import {ProviderTicket} from './tickets/provider/provider-ticket';
import {Type} from 'class-transformer';
import {User} from './user';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class RemoteDeviceLog {

    logId: number;
    emnifyId: number;

    @Type( () => Date)
    submittedAt: Date;

    @Type( () => Date)
    deliveredAt: Date;

    @Type( () => User)
    user?: User;
    userId: number;

    fromDevice?: RemoteDevice;
    fromDeviceId?: number;

    toDevice?: RemoteDevice;
    toDeviceId?: number;

    message: string;

    status: RemoteDeviceLogMessageStatus;

    deviceStatus: RemoteDeviceStatus;

    @Type( () => ProviderTicket)
    ticket?: ProviderTicket;

    static getStringifier(level: number): RemoteDeviceLog | null {
        if (level < 0) {
            return null;
        }
        return {
            logId: null,
            emnifyId: null,
            submittedAt: null,
            deliveredAt: null,
            user: User.getStringifier(level - 1),
            userId: null,
            fromDevice: RemoteDevice.getStringifier(level - 1),
            fromDeviceId: null,
            toDevice: RemoteDevice.getStringifier(level - 1),
            toDeviceId: null,
            message: null,
            status: null,
            deviceStatus: null,
            ticket: ProviderTicket.getStringifier(level - 1),
        } as RemoteDeviceLog;
    }
}

export enum RemoteDeviceLogMessageStatus {
    Pending = 'Pending',
    Delivered = 'Delivered',
    Failed = 'Failed'
}

export enum RemoteDeviceStatus {
    Ok = 'Ok',
    Warning = 'Warning',
    Error = 'Error'
}
