import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {Device, IDevice} from './Device';
import {DeviceMap, IDeviceMap} from './DeviceMap';

// tslint:disable-next-line:no-empty-interface
export interface ISprite extends Sprite {
}

@Stringifier
export class Sprite {
    spriteId: number;
    type: SpriteTypeEnum;
    x: number;
    y: number;

    deviceMapId: number;
    @Type(() => DeviceMap)
    deviceMap?: IDeviceMap;

    deviceId?: number;
    @Type(() => Device)
    device?: IDevice;

    isDeleted = false;

    static getStringifier(level: number): Sprite | null {
        if (level < 0) {
            return null;
        }
        return {
            spriteId: null,
            type: null,
            x: null,
            y: null,
            deviceMapId: null,
            deviceMap: DeviceMap.getStringifier(level - 1),
            deviceId: null,
            device: Device.getStringifier(level - 1),
            isDeleted: null,
        } as Sprite;
    }
}

export enum SpriteTypeEnum {
    Device,
}
