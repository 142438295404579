import {AfterViewInit, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {RestApiService} from '../../../../services/api';
import {FilterMatchMode, LazyLoadEvent} from 'primeng/api';
import {FilterTicketRequest} from '../../../../models/requests/filter-ticket-request';
import {getFilterRequest} from '../../../../services/api/filters/filter-utils';
import {ToastService} from '../../../../services/toast.service';
import {RestApiError} from '../../../../services/api/rest-api-error';
import {BehaviorSubject} from 'rxjs';
import {ListedResponse} from '../../../../models/hydratator/listed-response';
import {BooleanFilters} from '../../../../services/api/filters/boolean-filter';
import {RuleOperation} from '../../../../services/api/filters/filter';
import {MenuService} from '../../../../services/menu.service';
import {TicketsListMenuComponent} from './menu/tickets-list-menu.component';
import {ActivatedRoute, Params} from '@angular/router';
import {FString, StringFilters} from '../../../../services/api/filters/string-filter';
import {RoutingConstants} from '../../../../models/routing/routing-constants';
import {TicketGroupDto} from '../../../../models/responses/ticket-group-dto';
import {
    ClientTicketState,
    TicketArchived, TicketInSyncOption,
    TicketSearchDefaultOptions
} from '../../../../models/enums/ticket-states';
import {TicketSearchOption} from '../../../../models/tickets/provider/provider-ticket';
import {Title} from '@angular/platform-browser';
import {AclPermission} from '../../../../models/acl/acl-group';
import {TicketsTableColumns, TicketsTableComponent} from '../../../../components/tickets/tickets-table/tickets-table.component';

const {params} = RoutingConstants;

@Component({
    selector: 'app-tickets-list',
    templateUrl: './tickets-list.component.html',
    styleUrls: ['./tickets-list.component.scss']
})
export class TicketsListComponent implements AfterViewInit, OnInit {
    AclPermission = AclPermission;
    filterRequest: FilterTicketRequest = {} as FilterTicketRequest;

    columnsToShow: TicketsTableColumns[] = [];

    constructor(private api: RestApiService, private toast: ToastService,
                private menu: MenuService, private route: ActivatedRoute,
                private titleService: Title,
                private ngZone: NgZone) {
        this.titleService.setTitle('Lista ticket - Phoenix');
    }

    private sequentialRequestId = 0;

    private _triggerLoadTickets = new BehaviorSubject<LazyLoadEvent>(null);

    selectedArchivedOptions: TicketArchived = TicketArchived.NotArchived;
    archivedTicketOptions: TicketSearchOption[] = [
        {label: 'Aperti', code: TicketArchived.NotArchived},
        {label: 'Aperti e chiusi', code: TicketArchived.Archived}
    ];

    selectedTicketsInSyncOption: TicketInSyncOption = TicketInSyncOption.All;
    ticketsInSyncOptions: TicketSearchOption[] = [
        {label: 'Tutto', code: TicketInSyncOption.All},
        {label: 'Da sincronizzare', code: TicketInSyncOption.InSync},
    ];

    /**
     * Lista delle opzioni dei filtri personalizzati
     */
    options?: TicketSearchOption[] = undefined;

    /**
     * I ticket sono in fase di caricamento
     */
    ticketsAreLoading = false;

    /**
     * Lista dei ticket caricati
     */
    tickets: ListedResponse<TicketGroupDto> = {dataList: [], count: 0};

    @ViewChild('ticketTable') ticketTableRef: TicketsTableComponent;

    params: Params;

    async ngOnInit() {
        this.route.queryParams.subscribe(queryParams => this.params = queryParams);
        this._triggerLoadTickets.asObservable().subscribe(e => this._loadTickets(e));
        this.menu.setMenu({menu: TicketsListMenuComponent});

        const showTicketProviderTitle = (await this.api.userSettingsCached.getSetting('uiTicketsListShowTicketProviderTitle')).data;

        this.columnsToShow = TicketsTableComponent.columns;
        if (!showTicketProviderTitle) {
            this.columnsToShow = this.columnsToShow.filter(c => c !== ('providerTitle' as TicketsTableColumns));
        }
        this.columnsToShow = this.columnsToShow.filter(c => c !== ('lastClientMessageDate' as TicketsTableColumns));
    }

    ngAfterViewInit() {
        this.api.preferencesCached.getAllTicketListSearchOptions()
            .then(response => {
                this.options = response.data;
                this.applyFiltersFromParams(this.params);
                this.triggerLoadTickets();
            });
    }

    private async _loadTickets(event: LazyLoadEvent) {
        // Se le opzioni non sono state ancora caricate attendo il loro caricamento
        if (this.options === undefined) {
            return;
        }

        if (!event) {
            return;
        }

        this.ticketsAreLoading = true;
        try {
            this.filterRequest = getFilterRequest<FilterTicketRequest>(event);

            this.handleTicketInSync(this.filterRequest);
            this.handleUnknownStatus(this.filterRequest);
            this.handleArchivedTickets(this.filterRequest);
            this.handleOwners(this.filterRequest);
            this.handleInternal(this.filterRequest);
            this.handleMultiProviderFilter(this.filterRequest);
            this.handleMultiLoginFilter(this.filterRequest);

            this.filterRequest.sequentialRequestId = ++this.sequentialRequestId;

            const result = await this.api.tickets.tickets(this.filterRequest);
            if (result.data.sequentialRequestId >= this.sequentialRequestId) {
                // console.log per debug. Da rimuovere piu` avanti
                // console.log('Aggiorno lista ticket. ' + result.data.sequentialRequestId);
                this.tickets = result.data;
                this.ticketsAreLoading = false;
            } else {
                // console.log per debug. Da rimuovere piu` avanti
                // console.log('Ignoro aggiornamento lista ticket. ' + result.data.sequentialRequestId + ' vs ' + this.sequentialRequestId);
                // console.log({data: result.data});
            }
        } catch (e) {
            this.ticketsAreLoading = false;
            if (e instanceof RestApiError) {
                this.toast.error('Errore durante il caricamento della lista ticket', e.message);
            } else {
                throw e;
            }
        }
    }

    triggerLoadTickets(event?: LazyLoadEvent) {
        // se l'evento è null o undefined lo imposto come prima pagina
        if (!event) {
            event = this._triggerLoadTickets.value;
        }

        this._triggerLoadTickets.next(event);
    }

    handleUnknownStatus(request: FilterTicketRequest) {
        // GESTIONE FILTRO STATO
        // Quando filtro i ticket selezionando quelli che non hanno uno stato (tipicamente quelli che
        // non hanno un ticket lato cliente) viene inviato al server l'opzione `allowEmptyCollection`
        // per mantenere nei risultati quei ticketgroup che non hanno ticket lato cliente associato
        // (e che quindi non avrebbero uno stato del ticket)
        if (request.clientTickets?.stato &&
            (request.clientTickets.stato.filters[0]?.values.includes(ClientTicketState.Unknown) ?? false)) {
            request.clientTickets.stato.allowEmptyCollection = true;
        }
    }

    handleArchivedTickets(request: FilterTicketRequest) {
        // GESTIONE FILTRO TICKET ARCHIVIATI
        // I ticketgroup archiviati hanno un valore di `ignora` a true.
        // Se vogliamo solo quelli non archiviati possiamo filtrare selezionando solo i ticket
        // con `ignora` a false
        if (this.selectedArchivedOptions === TicketArchived.NotArchived) {
            request.ignora = {
                filterType: BooleanFilters.Equals,
                values: [false]
            };
        }
    }

    handleMultiProviderFilter(request: FilterTicketRequest) {
        // GESTIONE FILTRO PROVIDER
        // Dato che i provider sono presenti sia nei ticket lato cliente che nei ticket lato provider (e il filtro dovrebbe essere in OR),
        // anziché filtrare sul provider presente nel contratto del ticket facciamo un filtro custom
        if (request.contract?.provider && request.contract.provider?.filters?.length > 0) {
            request.providers = request.contract.provider.filters[0].values;
            request.contract.provider = null;
        }
    }

    handleMultiLoginFilter(request: FilterTicketRequest) {
        // GESTIONE FILTRO LOGIN
        // Dato che le login sono presenti sia nei ticket lato cliente che nei ticket lato provider (e il filtro dovrebbe essere in OR),
        // anziché filtrare sulla login presente nel contratto del ticket facciamo un filtro custom
        if (request.contract?.loginName && request.contract.loginName?.filters?.length > 0) {
            request.loginNameFilter = request.contract.loginName.filters[0].values[0];
            request.contract.loginName = null;
        }
    }

    handleTicketInSync(request: FilterTicketRequest) {
        // GESTIONE FILTRO TICKET IN SYNC
        // Se voglio visualizzare solo i ticket in sync devo filtrare quelli con `inSync` a true
        if (this.selectedTicketsInSyncOption === TicketInSyncOption.InSync) {
            // imposto automaticamente il filtro ALL a true
            this.ngZone.run(() => {
                const allOption = this.options.find(o => o.code === TicketSearchDefaultOptions.All);
                if (allOption) {
                    allOption.defaultValue = true;
                }
                request.inSync = true;
            });
        } else {
            request.inSync = false;
        }
    }

    handleInternal(request: FilterTicketRequest) {
        const selectedOptions = this.options.filter(o => o.defaultValue).map(o => o.code);
        if (!selectedOptions.includes(TicketSearchDefaultOptions.All) &&
            !selectedOptions.includes(TicketSearchDefaultOptions.Internal)) {
            // Se NON devo visualizzare anche i ticket interni
            // allora filtro la lista dei ticket chiedendo solo quelli che hanno
            // internal a false
            // Se voglio visualizzare anche i ticket interni basta non aggiungere nulla
            // al filtro
            request.internal = {
                filterType: BooleanFilters.Equals,
                values: [false]
            };
        }
    }

    handleOwners(request: FilterTicketRequest) {
        const selectedOptions = this.options.filter(o => o.defaultValue).map(o => o.code);

        // Assegnatari ticket da filtro
        request.owners = {
            ruleOperation: RuleOperation.And,
            filters: [
                ...(request.owners?.filters.map(f => ({
                    filterType: StringFilters.Contains,
                    values: f.values
                })) ?? [])]
        } as FString;

        if (!selectedOptions.includes(TicketSearchDefaultOptions.All)) {
            // Assegnatari ticket da opzioni
            request.owners = {
                ruleOperation: RuleOperation.And,
                filters: [
                    ...(request.owners?.filters ?? []),
                    {
                        filterType: StringFilters.Contains,
                        values: selectedOptions
                            .filter(code => code !== TicketSearchDefaultOptions.Internal)
                    }]
            } as FString;
        }
    }

    applyFiltersFromParams(queryParams: Params) {
        Object.entries(queryParams).forEach(([key, value]) => {
            switch (key) {
                case params.tickets.clientRagioneSociale:
                    // la ragione sociale viene passata come URI Component in quanto alcune ragioni sociali contengono caratteri speciali
                    this.filterAllByField(key, decodeURIComponent(value), FilterMatchMode.EQUALS);
                    break;
                case params.tickets.contractLoginName:
                    this.filterAllByField(key, value, FilterMatchMode.EQUALS);
                    break;
            }
        });
    }

    filterAllByField(field: string, value: string, matchMode: FilterMatchMode) {
        this.ticketTableRef.filters[field] = {
            matchMode: matchMode.toString(),
            value: value
        };

        this.options.filter(o => o.code === TicketSearchDefaultOptions.All).forEach(o => o.defaultValue = true);
        this.selectedArchivedOptions = TicketArchived.Archived;
    }

    static openPrefilteredTicketList(field: string, value: string) {
        const url = location.origin + `/tickets?${field}=${value}`;
        window.open(url, '_blank');
    }
}
