import {Component, Input} from '@angular/core';

export type ActionButtonType =
    'add'
    | 'save'
    | 'reload'
    | 'run'
    | 'delete'
    | 'info'
    | 'copy'
    | 'edit'
    | 'cancel'
    | 'clone'
    | 'link'
    | 'unlink'
    | 'calendar'
    | 'list'
    | 'confirm'
    | 'payment'
    | 'alert';

export type ActionButtonSeverity =
    'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'help'
    | 'danger';

@Component({
    selector: 'app-action-button',
    host: {
        '[style.pointer-events]': '"none"'
    },
    template: `
        <button pButton
                [class.p-0]="!padding"
                [disabled]="disabled || loading"
                [class.p-button-text]="textButton"
                [class.p-button-sm]="size === 'small'"
                [class.p-button-lg]="size === 'large'"
                [loading]="loading"
                [label]="label"
                [class]="buttonClass"
                [icon]="icon"
                [type]="buttonType"
                [iconPos]="iconPosition"
        ></button>
    `,
    styles: [`:host ::ng-deep button * {
        pointer-events: none;
    }`, `:host ::ng-deep button {
        pointer-events: all;
    }`]
})
export class ActionButtonComponent {
    @Input() label: string;

    @Input() loading = false;

    @Input() type?: ActionButtonType;

    @Input() disabled = false;

    @Input()
    padding = true;

    @Input()
    textButton = false;

    @Input()
    size: 'small' | 'medium' | 'large' = 'medium';

    @Input() buttonType: 'button' | 'submit' | 'reset' = 'button';

    @Input()
    iconPosition: 'left' | 'right' | 'top' | 'bottom' = 'left';

    @Input()
    hasIcon = true;

    @Input()
    severity: ActionButtonSeverity;

    _buttonClass: string;
    get buttonClass() {
        return this._buttonClass ? this._buttonClass : ('p-button-' + this.color);
    }

    @Input()
    set buttonClass(value: string) {
        this._buttonClass = value;
    }

    get icon() {
        if (!this.hasIcon) {
            return undefined;
        }

        switch (this.type) {
            case 'add':
                return 'fa fa-plus';
            case 'delete':
                return 'fa fa-trash';
            case 'info':
                return 'fa fa-info';
            case 'save':
                return 'fa fa-save';
            case 'run':
                return 'fa fa-gears';
            case 'reload':
                return 'fa fa-sync-alt';
            case 'copy':
                return 'fa fa-paste';
            case 'edit':
                return 'fa fa-pen';
            case 'cancel':
                return 'fa fa-times';
            case 'clone':
                return 'fa fa-clone';
            case 'link':
                return 'fa fa-link';
            case 'unlink':
                return 'fa fa-unlink';
            case 'confirm':
                return 'fa fa-check';
            case 'calendar':
                return 'fa fa-calendar';
            case 'list':
                return 'fa fa-list';
            case 'payment':
                return 'fa fa-euro-sign';
            case 'alert':
                return 'fa-solid fa-circle-exclamation';
            default:
                return undefined;
        }
    }

    get color(): ActionButtonSeverity {

        if (this.severity) {
            return this.severity;
        }

        switch (this.type) {
            case 'add':
                return 'info';
            case 'copy':
                return 'secondary';
            case 'info':
                return 'info';
            case 'save':
                return 'success';
            case 'delete':
                return 'danger';
            case 'reload':
                return 'secondary';
            case 'run':
                return 'secondary';
            case 'cancel':
                return 'secondary';
            case 'clone':
                return 'secondary';
            case 'link':
                return 'primary';
            case 'unlink':
                return 'danger';
            case 'confirm':
                return 'success';
            case 'calendar':
                return 'secondary';
            case 'alert':
                return 'danger';
            default:
                return 'secondary';
        }
    }
}
