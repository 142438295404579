import {Type} from 'class-transformer';
import {EasyCastLocation} from './easy-cast-location';
import {Stringifier} from '../../services/stringifier.service';

// tslint:disable-next-line:no-empty-interface
export interface IEasyCastUser extends EasyCastUser {
}

@Stringifier
export class EasyCastUser {
    userId: number;

    username: string;

    /**
     * In UTC. Pertanto usare la pipe "date" e non "appDate"
     */
    @Type(() => Date)
    createdAt: Date;
    /**
     * In UTC. Pertanto usare la pipe "date" e non "appDate"
     */
    @Type(() => Date)
    updatedAt: Date;

    twoFactorKey: string;

    /**
     * In UTC. Pertanto usare la pipe "date" e non "appDate"
     */
    @Type(() => Date)
    emailValidatedAt: Date;

    active = true;

    userType: UserType;

    // Ragione sociale
    businessName: string;
    fiscalCode: string;
    vatNumber: string;
    address: string;
    city: string;
    province: string;
    zipCode: string;
    country: string;
    note: string;

    @Type(() => EasyCastUser)
    parentUser: EasyCastUser;

    parentUserId: number;

    @Type(() => EasyCastLocation)
    locations: EasyCastLocation[];

    static getStringifier(level: number): EasyCastUser | null {
        if (level < 0) {
            return null;
        }
        return {
            userId: null,
            username: null,
            createdAt: null,
            updatedAt: null,
            twoFactorKey: null,
            emailValidatedAt: null,
            active: null,
            userType: null,
            businessName: null,
            fiscalCode: null,
            vatNumber: null,
            address: null,
            city: null,
            province: null,
            zipCode: null,
            country: null,
            note: null,
            parentUser: EasyCastUser.getStringifier(level - 1),
            parentUserId: null,
            locations: null,
        } as EasyCastUser;
    }
}

export enum UserType {
    Unknown = 0,
    Host = 1,
    Reseller = 2,
    Dealer = 3,
}
