<p-columnFilter
    [field]="field"
    matchMode="in"
    [showMenu]="false"
    [showClearButton]="false"
>
    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
        <p-multiSelect
            appendTo="body"
            [style.width]="inputWidth"
            [panelStyle]="{'width': inputWidth}"
            [options]="entries"
            [group]="grouped"
            optionLabel="label"
            optionValue="key"
            optionGroupLabel="group"
            optionGroupChildren="entries"
            maxSelectedLabels="3"
            (onChange)="filter($event.value)"
            [showHeader]="true"
            [placeholder]="placeholder"
        >
            <ng-template pTemplate="footer">
                <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
            </ng-template>

        </p-multiSelect>
    </ng-template>

</p-columnFilter>
