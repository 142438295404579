import {FilterMetadata} from 'primeng/api/filtermetadata';
import {LazyLoadEvent} from 'primeng/api';
import {Filter, FilterRule, RuleOperation} from './filter';
import {StringFilters} from './string-filter';
import {BaseFilterRequest} from '../../../models/requests/base-filter-request';

export function getFilterRequest<T extends BaseFilterRequest>(event: LazyLoadEvent) {
    return {
        ...fromPrimeNgFilterToRequestFilter<T>(event.filters ?? {}),
        from: event.first ?? 0,
        amount: event.rows ?? 0,
        sortingKey: event.sortField ?? 'Created',
        sortingDirectionAsc: event.sortOrder > 0,
    } as T;
}

function fromPrimeNgFilterToRequestFilter<T extends BaseFilterRequest>(primeNgFilters: {[s: string]: FilterMetadata}) {

    // coppie di
    // 1) array che definiscono il "percorso" all'interno dell'albero delle proprietà di un oggetto (e.g. ['client', 'ragioneSociale'])
    // 2) filtri associati ad ogni proprietà

    const filtersPath = Object.entries(primeNgFilters)
        .filter(([key, value]) => value && (!Array.isArray(value?.value) || value?.value.length > 0))
        // TODO: fintanto che non risolvono lato primeNG
        // https://github.com/primefaces/primeng/issues/10055
        .map(([key, value]) => ({key,
            value: (value instanceof Object && !Array.isArray(value) ? [value] : value) as FilterMetadata[]
        }))
        .map(({key, value}) => ({
            key,
            value: value?.filter(v => v.value)
        }))
        .filter(({key, value}) => value?.length > 0)
        // scompongo il path che associa filtro alla proprietà di un oggetto (e.g., cliente.id)
        .map(({key, value}) => ({path: key.split('.'), value}));

    return groupFiltersByFatherProperty<T>(filtersPath);
}

function groupFiltersByFatherProperty<T extends BaseFilterRequest>(filterPaths: {path: string[], value: FilterMetadata[]}[]) {
    if (filterPaths.length === 0) {
        return {};
    }
    if (filterPaths.length === 1 && filterPaths[0].path.length === 0) {
        return getFilterRule(filterPaths[0].value);
    }

    const parents = filterPaths.map(({path}) => path[0]);
    return Object.fromEntries(parents.map(parent => [
        parent,
        groupFiltersByFatherProperty(filterPaths
        .filter(({path}) => path[0] === parent)
        .map(({path: [_, ...rest], value}) => ({path: rest, value})))
        ]
    ));
}


function getRuleOperator(metadata: FilterMetadata) {
    return metadata.operator === 'and' ? RuleOperation.And : RuleOperation.Or;
}

function getFilter<T, F extends StringFilters >(metadata: FilterMetadata) {
    const values = (Array.isArray(metadata.value) ? metadata.value : [metadata.value]) as T[];

    return {
        filterType: metadata.matchMode,
        values: values.map(v => typeof v === 'string' ? v.trim() : v)
    } as Filter<T, F>;
}

function getFilterRule<T extends Filter<unknown, unknown>>(metadata: FilterMetadata[]) {
    return {
        ruleOperation: metadata ? getRuleOperator(metadata[0]) : RuleOperation.And,
        filters: metadata.map(getFilter)
    } as FilterRule<T>;
}


