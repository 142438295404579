import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ThemeChooserComponent} from './theme-chooser/theme-chooser.component';
import {TestTableComponent} from './test-table.component';
import {UnreadTicketsComponent} from './tickets/unread-tickets.component';
import {PipesModule} from '../pipes/pipes.module';
import {TableModule} from 'primeng/table';
import {BadgeModule} from 'primeng/badge';
import {PanelModule} from 'primeng/panel';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {StringFilterComponent} from './table/filters/string/string-filter.component';
import {EnumFilterComponent} from './table/filters/enum/enum-filter.component';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {StyleClassModule} from 'primeng/styleclass';
import {FormsModule} from '@angular/forms';
import {CounterBadgeComponent} from './table/counter-badge/counter-badge.component';
import {IntFilterComponent} from './table/filters/int/int-filter.component';
import {InputComponent} from './form/input/input.component';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DropdownComponent} from './form/dropdown/dropdown.component';
import {UserLabelComponent} from './form/user-label/user-label.component';
import {CheckboxListComponent} from './form/checkbox-list/checkbox-list.component';
import {AccordionModule} from 'primeng/accordion';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TdUnreadMessagesComponent} from './tickets/tickets-list/td-content-components/td-unread-messages.component';
import {TdContractComponent} from './tickets/tickets-list/td-content-components/td-contract.component';
import {TdContractTreeComponent} from './tickets/tickets-list/td-content-components/td-contract-tree/td-contract-tree.component';
import {TdFlagsComponent} from './tickets/tickets-list/td-content-components/td-flags.component';
import {TdChatsComponent} from './tickets/tickets-list/td-content-components/td-chats.component';
import {TdTicketTitleComponent} from './tickets/tickets-list/td-content-components/td-ticket-title.component';
import {TdClosuresComponent} from './tickets/tickets-list/td-content-components/td-closures.component';
import {TdAlarmsComponent} from './tickets/tickets-list/td-content-components/td-alarms.component';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ClientHeaderInformationsComponent} from './client-header-informations/client-header-informations.component';
import {DirectivesModule} from '../directives/directives.module';
import {CheckboxComponent} from './form/checkbox/checkbox.component';
import {ClientsListComponent} from './clients/clients-list/clients-list.component';
import {ClientAreaButtonComponent} from './client-area-button/client-area-button.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AutocompleteComponent} from './form/autocomplete/autocomplete.component';
import {CommandEditorComponent} from './remote-devices/command-editor/command-editor.component';
import {DividerModule} from 'primeng/divider';
import {ParameterEditorComponent} from './remote-devices/command-editor/parameter-editor/parameter-editor.component';
import {ActionButtonComponent} from './form/action-buttons/action-button.component';
import {SendCommandComponent} from './remote-devices/send-command/send-command.component';
import {LogsListComponent} from './remote-devices/logs-list/logs-list.component';
import {LogMessageComponent} from './remote-devices/logs-list/log-message/log-message.component';
import {CardModule} from 'primeng/card';
import {TagModule} from 'primeng/tag';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {PaginatorModule} from 'primeng/paginator';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {FieldsetModule} from 'primeng/fieldset';
import {LogMessageStatusComponent} from './remote-devices/logs-list/log-message-status/log-message-status.component';
import {LogDeviceStatusComponent} from './remote-devices/logs-list/log-device-status/log-device-status.component';
import {InlineIconPickerComponent} from './form/icon-picker/inline-icon-picker.component';
import {TabViewModule} from 'primeng/tabview';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {IconPickerComponent} from './form/icon-picker/icon-picker.component';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ClientFlagAssociationComponent} from './clients/client-flag-association/client-flag-association.component';
import {ClientFlagSelectorComponent} from './clients/client-flag-selector/client-flag-selector.component';
import {BlockUIModule} from 'primeng/blockui';
import {GenericInputDialogComponent} from './dialogs/generic-input-dialog/generic-input-dialog.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {ClientReminderComponent} from './clients/client-reminder/client-reminder.component';
import {CalendarModule} from 'primeng/calendar';
import {DatetimeComponent} from './form/datetime/datetime.component';
import {CalendarNotificationComponent} from './notifications/calendar-notification/calendar-notification.component';
import {NotificationComponent} from './notifications/notification/notification.component';
import {BaseNotificationComponent} from './notifications/base-notification/base-notification.component';
import {
    CalendarNotificationDialogComponent
} from './notifications/calendar-notification/calendar-dialog/calendar-notification-dialog.component';
import {BlockableDivComponent} from './form/blockable-div/blockable-div.component';
import {ContractStatusIconComponent} from './contracts/contract-status-icon/contract-status-icon.component';
import {
    SendResetConnectivityCommandComponent
} from './remote-devices/send-reset-connectivity-command/send-reset-connectivity-command.component';
import {DateFilterComponent} from './table/filters/date/date-filter.component';
import {UserSelectorComponent} from './form/user-selector/user-selector.component';
import {ContractIpDataComponent} from './contracts/contract-router-info/contract-ip-data/contract-ip-data.component';
import {SpinnerModule} from 'primeng/spinner';
import {MessageModule} from 'primeng/message';
import {SplitButtonModule} from 'primeng/splitbutton';
import {DialogModule} from 'primeng/dialog';
import {ContractPasswordsComponent} from './contracts/contract-router-info/contract-passwords/contract-passwords.component';
import {ContractElementsComponent} from './contracts/contract-elements/contract-elements.component';
import {ContractDataComponent} from './contracts/contract-data/contract-data.component';
import {NewTicketButtonComponent} from './tickets/new-ticket/new-ticket-button.component';
import {SearchTicketsButtonComponent} from './tickets/search-tickets/search-tickets-button.component';
import {ContractInfoButtonComponent} from './contracts/contract-info-button/contract-info-button.component';
import {ContractAnnotationComponent} from './contracts/contract-annotation/contract-annotation.component';
import {EoliaButtonComponent} from './contracts/eolia-button/eolia-button.component';
import {ContractCoverageInfoStatusComponent} from './contracts/contract-coverage-info-status/contract-coverage-info-status.component';
import {SynchronizeDeviceButtonComponent} from './remote-devices/synchronize-device-button/synchronize-device-button.component';
import {TdTicketGroupLinkComponent} from './tickets/tickets-list/td-content-components/td-ticket-group-link.component';
import {VersionComponent} from './version/version.component';
import {ContractSelectorComponent} from './form/contract-selector/contract-selector.component';
import {RippleModule} from 'primeng/ripple';
import {MenuModule} from 'primeng/menu';
import {ClientUsernamePasswordComponent} from './clients/client-private-auth-area-parameters/client-username-password.component';
import {ContractFullViewComponent} from './contracts/contract-full-view/contract-full-view.component';
import {
    SearchTicketsByContractButtonComponent
} from './tickets/search-tickets/search-tickets-by-contract-button.component';
import {ContractNoteComponent} from './contracts/contract-note/contract-note.component';
import {EditorModule} from 'primeng/editor';
import {ContractColorizedLineComponent} from './contracts/contract-colorized-line.component';
import {TicketGroupLinkComponent} from './tickets/ticket-group-link/ticket-group-link.component';
import {ContractRouterInfoComponent} from './contracts/contract-router-info/contract-router-info.component';
import {MikrotikGraphComponent} from './contracts/mikrotik-graph/mikrotik-graph.component';
import {ChartModule} from 'primeng/chart';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ContractEditLoginComponent} from './contracts/contract-edit-login/contract-edit-login.component';
import {ContractAssociateParentComponent} from './contracts/contract-associate-parent/contract-associate-parent.component';
import {ScheduleCommandComponent} from './remote-devices/schedule-command/schedule-command.component';
import {InputMaskModule} from 'primeng/inputmask';
import {TimeComponent} from './form/time/time.component';
import {FastDialNumbersComponent} from './form/fast-dial-numbers/fast-dial-numbers.component';
import {ClientSelectorComponent} from './form/client-selector/client-selector.component';
import {
    PayEasyCastContractButtonComponent
} from './easycast/easycast-contracts/pay-easycast-contract-button/pay-easy-cast-contract-button.component';
import {
    GoToEasyCastUserButtonComponent
} from './easycast/easycast-contracts/go-to-easycast-user-button/go-to-easy-cast-user-button.component';
import {
    PayEasyCastContractDialogComponent
} from './easycast/easycast-contracts/pay-easycast-contract-dialog/pay-easy-cast-contract-dialog.component';
import {GoToClientFormButtonComponent} from './clients/go-to-client-form-button/go-to-client-form-button.component';
import {EasyCastContractFormComponent} from './easycast/easy-cast-contract-form/easy-cast-contract-form.component';
import {
    GoToEasyCastContractButtonComponent
} from './easycast/easycast-contracts/go-to-easy-cast-contract-button/go-to-easy-cast-contract-button.component';
import {
    SendEasyCastContractButtonComponent
} from './easycast/easycast-contracts/send-easycast-contract-button/send-easy-cast-contract-button.component';
import {
    ConfirmEasycastContractButtonComponent
} from './easycast/easycast-contracts/confirm-easy-cast-contract-button/confirm-easycast-contract-button.component';
import {
    DeleteEasyCastContractButtonComponent
} from './easycast/easycast-contracts/delete-easy-cast-contract-button/delete-easy-cast-contract-button.component';
import {DateSelectionComponent} from './easycast/easycast-contracts/date-selection/date-selection.component';
import {
    TdContractPressoDedupedComponent
} from './tickets/tickets-list/td-content-components/td-contract-presso-deduped.component';
import { ClientAccessDetailsComponent } from './clients/client-access-details/client-access-details.component';
import { ConfigureEasycastContractButtonComponent } from './easycast/easycast-contracts/configure-easycast-contract-button/configure-easycast-contract-button.component';
import { ShippedEasyCastDevicesComponent } from './easycast/easycast-contracts/shipped-easycast-devices/shipped-easy-cast-devices.component';
import { DeliveredEasycastDevicesComponent } from './easycast/easycast-contracts/delivered-easycast-devices/delivered-easycast-devices.component';
import { TicketsTableComponent } from './tickets/tickets-table/tickets-table.component';
import { MenuWithNavigationButtonsComponent } from './menu/menu-with-navigation-buttons/menu-with-navigation-buttons.component';

@NgModule({
    declarations: [
        ThemeChooserComponent,
        TestTableComponent,
        UnreadTicketsComponent,
        StringFilterComponent,
        DateFilterComponent,
        EnumFilterComponent,
        CounterBadgeComponent,
        IntFilterComponent,
        InputComponent,
        DropdownComponent,
        UserLabelComponent,
        CheckboxListComponent,
        TdUnreadMessagesComponent,
        ClientHeaderInformationsComponent,
        TdContractComponent,
        TdContractTreeComponent,
        TdFlagsComponent,
        TdChatsComponent,
        TdTicketTitleComponent,
        TdClosuresComponent,
        TdAlarmsComponent,
        CheckboxComponent,
        ClientsListComponent,
        ClientAreaButtonComponent,
        InlineIconPickerComponent,
        IconPickerComponent,
        ClientFlagAssociationComponent,
        ClientFlagSelectorComponent,
        AutocompleteComponent,
        CommandEditorComponent,
        ParameterEditorComponent,
        ActionButtonComponent,
        SendCommandComponent,
        LogsListComponent,
        LogMessageComponent,
        LogMessageStatusComponent,
        LogDeviceStatusComponent,
        GenericInputDialogComponent,
        ClientReminderComponent,
        DatetimeComponent,
        CalendarNotificationComponent,
        NotificationComponent,
        BaseNotificationComponent,
        CalendarNotificationDialogComponent,
        BlockableDivComponent,
        ContractStatusIconComponent,
        SendResetConnectivityCommandComponent,
        UserSelectorComponent,
        ContractIpDataComponent,
        ContractPasswordsComponent,
        ContractElementsComponent,
        ContractDataComponent,
        NewTicketButtonComponent,
        SearchTicketsButtonComponent,
        ContractInfoButtonComponent,
        ContractAnnotationComponent,
        EoliaButtonComponent,
        ContractCoverageInfoStatusComponent,
        SynchronizeDeviceButtonComponent,
        TdTicketGroupLinkComponent,
        VersionComponent,
        ContractSelectorComponent,
        ClientUsernamePasswordComponent,
        ContractFullViewComponent,
        SearchTicketsByContractButtonComponent,
        ContractNoteComponent,
        ContractColorizedLineComponent,
        TicketGroupLinkComponent,
        ContractRouterInfoComponent,
        MikrotikGraphComponent,
        ContractEditLoginComponent,
        ContractAssociateParentComponent,
        ScheduleCommandComponent,
        TimeComponent,
        FastDialNumbersComponent,
        ClientSelectorComponent,
        PayEasyCastContractButtonComponent,
        GoToEasyCastUserButtonComponent,
        PayEasyCastContractDialogComponent,
        GoToClientFormButtonComponent,
        EasyCastContractFormComponent,
        GoToEasyCastContractButtonComponent,
        SendEasyCastContractButtonComponent,
        ConfirmEasycastContractButtonComponent,
        DeleteEasyCastContractButtonComponent,
        DateSelectionComponent,
        TdContractPressoDedupedComponent,
        ClientAccessDetailsComponent,
        ConfigureEasycastContractButtonComponent,
        ShippedEasyCastDevicesComponent,
        DeliveredEasycastDevicesComponent,
        TicketsTableComponent,
        MenuWithNavigationButtonsComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        TooltipModule,
        TableModule,
        BadgeModule,
        PipesModule,
        PanelModule,
        DropdownModule,
        MultiSelectModule,
        StyleClassModule,
        FormsModule,
        InputTextModule,
        InputTextareaModule,
        AccordionModule,
        ToggleButtonModule,
        DirectivesModule,
        InputSwitchModule,
        TabViewModule,
        CardModule,
        OverlayPanelModule,
        TieredMenuModule,
        BlockUIModule,
        ProgressSpinnerModule,
        TagModule,
        ProgressBarModule,
        CalendarModule,
        AutoCompleteModule,
        DividerModule,
        VirtualScrollerModule,
        PaginatorModule,
        ScrollPanelModule,
        FieldsetModule,
        SpinnerModule,
        MessageModule,
        SplitButtonModule,
        DialogModule,
        RippleModule,
        EditorModule,
        MenuModule,
        ChartModule,
        SelectButtonModule,
        InputMaskModule,
    ],
    exports: [
        UnreadTicketsComponent,
        ThemeChooserComponent,
        TestTableComponent,
        StringFilterComponent,
        IntFilterComponent,
        EnumFilterComponent,
        CounterBadgeComponent,
        InputComponent,
        DropdownComponent,
        UserLabelComponent,
        CheckboxListComponent,
        TdUnreadMessagesComponent,
        ClientHeaderInformationsComponent,
        TdContractComponent,
        TdContractTreeComponent,
        TdFlagsComponent,
        TdChatsComponent,
        TdTicketTitleComponent,
        TdClosuresComponent,
        TdAlarmsComponent,
        ClientsListComponent,
        CheckboxComponent,
        InlineIconPickerComponent,
        IconPickerComponent,
        AutocompleteComponent,
        CommandEditorComponent,
        ActionButtonComponent,
        SendCommandComponent,
        LogsListComponent,
        LogMessageStatusComponent,
        LogDeviceStatusComponent,
        NotificationComponent,
        GenericInputDialogComponent,
        SendResetConnectivityCommandComponent,
        ContractStatusIconComponent,
        ContractAnnotationComponent,
        UserSelectorComponent,
        ContractIpDataComponent,
        ContractPasswordsComponent,
        ContractElementsComponent,
        DateFilterComponent,
        ContractDataComponent,
        EoliaButtonComponent,
        BlockableDivComponent,
        DatetimeComponent,
        SynchronizeDeviceButtonComponent,
        TdTicketGroupLinkComponent,
        VersionComponent,
        ContractSelectorComponent,
        ClientAreaButtonComponent,
        ClientUsernamePasswordComponent,
        ContractFullViewComponent,
        NewTicketButtonComponent,
        SearchTicketsButtonComponent,
        SearchTicketsByContractButtonComponent,
        ContractNoteComponent,
        ContractColorizedLineComponent,
        ContractInfoButtonComponent,
        ContractRouterInfoComponent,
        TicketGroupLinkComponent,
        MikrotikGraphComponent,
        ContractEditLoginComponent,
        FastDialNumbersComponent,
        ClientSelectorComponent,
        PayEasyCastContractButtonComponent,
        GoToClientFormButtonComponent,
        GoToEasyCastUserButtonComponent,
        EasyCastContractFormComponent,
        GoToEasyCastContractButtonComponent,
        DeleteEasyCastContractButtonComponent,
        SendEasyCastContractButtonComponent,
        ConfirmEasycastContractButtonComponent,
        DateSelectionComponent,
        TdContractPressoDedupedComponent,
        ConfigureEasycastContractButtonComponent,
        ShippedEasyCastDevicesComponent,
        DeliveredEasycastDevicesComponent,
        TicketsTableComponent
    ]
})
export class ComponentsModule {
}
