import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {ConnectedNetworkInterface, IConnectedNetworkInterface} from './ConnectedNetworkInterface';
import {Device, IDevice} from './Device';
import {INetworkInterfaceCustomFieldType, NetworkInterfaceCustomFieldType} from './NetworkInterfaceCustomFieldType';
import {INetworkInterfaceTemplate, InternalTypeEnum, NetworkInterfaceTemplate} from './NetworkInterfaceTemplate';

// tslint:disable-next-line:no-empty-interface
export interface INetworkInterface extends NetworkInterface {
}

@Stringifier
export class NetworkInterface {
    networkInterfaceId: number;
    name: string;

    deviceId: number;
    @Type(() => Device)
    device: IDevice;

    networkInterfaceTemplateId: number;
    @Type(() => NetworkInterfaceTemplate)
    networkInterfaceTemplate?: INetworkInterfaceTemplate;

    @Type(() => NetworkInterfaceCustomFieldType)
    networkInterfaceCustomFieldTypes: INetworkInterfaceCustomFieldType[];

    @Type(() => ConnectedNetworkInterface)
    connectedNetworkInterfacesStart: IConnectedNetworkInterface[] = [];
    @Type(() => ConnectedNetworkInterface)
    connectedNetworkInterfacesEnd: IConnectedNetworkInterface[] = [];

    get connectedNetworkInterfacesAll() {
        return this.connectedNetworkInterfacesStart.concat(this.connectedNetworkInterfacesEnd);
    }

    set connectedNetworkInterfacesAll(value: IConnectedNetworkInterface[]) {
        const all = value
            .filter((cni) => cni.networkInterfaceStartId !== cni.networkInterfaceEndId)
            .map((cni) => {
                if (cni.networkInterfaceStartId < cni.networkInterfaceEndId) {
                    return cni;
                }

                const tmpId = cni.networkInterfaceStartId;
                cni.networkInterfaceStartId = cni.networkInterfaceEndId;
                cni.networkInterfaceEndId = tmpId;
                const tmpNetworkInterface = cni.networkInterfaceStart;
                cni.networkInterfaceStart = cni.networkInterfaceEnd;
                cni.networkInterfaceEnd = tmpNetworkInterface;

                return cni;
            })
            .filter(
                (cni, index) => {
                    // check if there is a duplicate
                    for (let i = 0; i < index; i++) {
                        if (cni.networkInterfaceStartId === value[i].networkInterfaceStartId
                            && cni.networkInterfaceEndId === value[i].networkInterfaceEndId) {
                            return false;
                        }
                    }
                    return true;
                }
            );

        this.connectedNetworkInterfacesStart = all.filter((cni) => cni.networkInterfaceStartId === this.networkInterfaceId);
        this.connectedNetworkInterfacesEnd = all.filter((cni) => cni.networkInterfaceEndId === this.networkInterfaceId);
    }

    get isUserDefined() {
        return this.networkInterfaceTemplate.internalType === InternalTypeEnum.UserDefined;
    }

    get isSelfConnection() {
        return this.networkInterfaceTemplate.internalType === InternalTypeEnum.Self;
    }

    static getStringifier(level: number): NetworkInterface | null {
        if (level < 0) {
            return null;
        }
        return {
            networkInterfaceId: null,
            name: null,
            deviceId: null,
            device: Device.getStringifier(level - 1),
            networkInterfaceTemplateId: null,
            networkInterfaceTemplate: NetworkInterfaceTemplate.getStringifier(level - 1),
            networkInterfaceCustomFieldTypes: null,
            connectedNetworkInterfacesStart: null,
            connectedNetworkInterfacesEnd: null,
        } as NetworkInterface;
    }
}
