import {Component, Input, OnInit} from '@angular/core';
import {TicketsListComponent} from '../../../views/intranet/tickets/tickets-list/tickets-list.component';

@Component({
    selector: 'app-search-tickets-button',
    template: `
        <button
            pButton type="button" icon="fa fa-search" class="p-button-sm p-button-text p-0 m-0"
            (click)="search()"
        ></button>
    `
})
export class SearchTicketsButtonComponent implements OnInit {

    @Input()
    field: string;

    @Input()
    value: string;

    constructor() {
    }

    ngOnInit(): void {
    }

    search() {
        TicketsListComponent.openPrefilteredTicketList(this.field, this.value);
    }

}
