import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-counter-badge',
    templateUrl: './counter-badge.component.html',
    styleUrls: ['./counter-badge.component.scss']
})
export class CounterBadgeComponent {
    @Input() count = 0;
    @Input() totalCount: number = null;
    @Input() entitySingular = 'elemento';
    @Input() entityPlural = 'elementi';

    get message() {
        const overTotal = this.totalCount != null ? (' / ' + this.totalCount) : '';
        return this.count === 0 || this.count > 1
            ? `${(this.count)}${overTotal} ${this.entityPlural} trovati`
            : `${(this.count)}${overTotal} ${this.entitySingular} trovato`;
    }
}
