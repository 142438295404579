import {Type} from 'class-transformer';
import {Stringifier} from '../services/stringifier.service';

@Stringifier
export class ZucchettiContract {
    id: number;
    coserial: string;
    idzucchetti: string;
    descrizione: string;
    note: string;
    pag: string;
    quit: boolean;
    codiceCom: string;
    codiceCup: string;
    codiceCig: string;
    numeroDoc: string;
    hasSplitPayment: boolean;

    total: number;
    vattedTotal: number;
    totalNoCommissions: number;
    vattedTotalNoCommissions: number;

    @Type(() => Date)
    dataDoc?: Date;

    @Type(() => ZucchettiContractElement)
    zucchettiContractElements: ZucchettiContractElement[] = [];

    get SLA() {
        return this.zucchettiContractElements?.find(e => e.SLA)?.SLA ?? null;
    }

    static getStringifier(level: number): ZucchettiContract | null {
        if (level < 0) {
            return null;
        }
        return {
            id: null,
            coserial: null,
            idzucchetti: null,
            descrizione: null,
            note: null,
            pag: null,
            quit: null,
            codiceCom: null,
            codiceCup: null,
            codiceCig: null,
            numeroDoc: null,
            hasSplitPayment: null,
            total: null,
            vattedTotal: null,
            totalNoCommissions: null,
            vattedTotalNoCommissions: null,
            dataDoc: null,
            zucchettiContractElements: null,
        } as ZucchettiContract;
    }
}

@Stringifier
export class ZucchettiContractElement {
    coserial: string;
    articleModel: string;
    descrizione: string;
    qty: number;
    prezzo: number;
    um: string;
    disabled: boolean;
    descrizioneExt: string;
    codArticolo: string;
    ivaPercentuale: number;
    isSplitPayment: boolean;
    vattedPrice: number;
    subElements: string[];
    periodicity: DocumentPeriodicity;

    get SLA() {
        if (this.articleModel === 'SLA') {
            return this.descrizione;
        }

        const matches = this.descrizioneExt?.match(/; (?<sla>SLA[^;]*)(?:;.*|$)/);
        if (matches) {
            return matches.groups.sla;
        }

        return null;
    }

    static getStringifier(level: number): ZucchettiContractElement | null {
        if (level < 0) {
            return null;
        }
        return {
            coserial: null,
            articleModel: null,
            descrizione: null,
            qty: null,
            prezzo: null,
            um: null,
            disabled: null,
            descrizioneExt: null,
            codArticolo: null,
            ivaPercentuale: null,
            isSplitPayment: null,
            vattedPrice: null,
            subElements: null,
            periodicity: null,
        } as ZucchettiContractElement;
    }
}

export enum DocumentPeriodicity {
    OneOff= 'OneOff',
    BiMonthly = 'BiMonthly',
    ThreeMonthly = 'ThreeMonthly',
    SixMonthly = 'SixMonthly',
    Annual = 'Annual',
    Biennial = 'Biennal',
    Triennial = 'Triennal',
    Unknown = 'Unknown'
}
