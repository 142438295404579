import {ContractService} from './contract-service';
import {Type} from 'class-transformer';
import {Stringifier} from '../services/stringifier.service';
import {LoginRouter} from './login-router';
import {ZucchettiContract} from './zucchetti-contract';
import {MaintenanceAlert} from './maintenance-alert';
import {Provider} from './enums/provider';
import {getMftwebUrl} from '../views/mftweb/mftweb/mftweb.component';
import {ContractClient} from './contract-client';
import {Client} from './clients/client';

// import {createMock} from 'ts-auto-mock';

@Stringifier
export class Contract {

    get technicalLogin() {
        return this.loginName.includes('@openfiber') ? 'openfiber' : this.loginName;
    }

    get fullAddress() {
        return `${this.indirizzo}, ${this.citta} (${this.provincia})`;
    }

    get technicalUrl() {
        switch (this.provider) {
            case Provider.EOLO:
                return getMftwebUrl(`/Ticket/Detail/EoloRedirect.php?provider=EOLO&login=${this.loginName}&hlan=${this.hlan}`);
            case Provider.EOLOWH:
                return `https://area-riservata.eolo.it/ar-whs/servizi/servizi/gestione-tecnica.html?serviceLogin=${this.loginName}`;
            default:
                return this.linkTecnico;
        }
    }

    get commercialUrl() {
        switch (this.provider) {
            case Provider.EOLO:
                return `https://area-riservata.eolo.it/ar-rivenditori/contratti/servizi.html?contractFamily=Wireless&contractLogin=${this.loginName}&contractCode=${this.hlan}`;
            default:
                return null;
        }
    }

    get administrativeUrl() {
        switch (this.provider) {
            case Provider.EOLO:
                return null;
            case Provider.EOLOWH:
                return null;
            default:
                return this.linkAmministrativo;
        }
    }

    contractId: number;
    loginName: string;

    parentContractId?: number;

    presso: string;
    @Type(() => Date)
    dal: Date;
    @Type(() => Date)
    al: Date;
    hlan: string;
    cf: string;
    piva: string;
    indirizzo: string;
    citta: string;
    provincia: string;
    stato: ContractStateExpanded;
    stateSimplified: ContractState;
    bts: string;
    famiglia: string;
    voce: string;
    mac: string;
    servizioId?: number;
    servizio: string;
    profilo: string;
    added?: number;
    note?: string;

    contractNotes: ContractNote[] = null;

    @Type(() => Date)
    ts: Date;
    provider: Provider;
    codcon: string;
    easyName: string;
    ticketMode: number;
    ticketDescription: string;
    doCoverage?: number;
    loginMaster: string;
    ultimoIp: string;

    @Type(() => ContractService)
    servizioNavigation: ContractService;

    tipoUltimoIp: string;

    ultimaClasseIp: string;

    ultimoRadixIp: string;

    ultimoGatewayIp: string;

    primoIndirizzoDispPerUltimoIp: string;

    ultimoIndirizzoDispPerUltimoIp: string;

    broadcastUltimoIp: string;

    numeroIndirizziPerUltimoIp?: number;

    subnetUltimoIp: string;

    protocolloConfigurato: string;

    ipConfigurato: string;

    portaConfigurata: string;

    linkInfrastruttura: string;

    @Type(() => Date)
    dataUltimoIp: Date;

    acs2: string;

    linkTecnico: string;

    linkAmministrativo: string;

    loginPrimaria: boolean;
    codiceDelivery: string;
    ticketOverride: string;

    get hasTicketOverride(): boolean {
        return this.ticketOverride && this.ticketOverride !== '';
    }

    @Type(() => LoginRouter)
    contractRouter: LoginRouter;

    @Type(() => ZucchettiContract)
    zucchettiContract: ZucchettiContract;

    @Type(() => MaintenanceAlert)
    maintenanceAlerts: MaintenanceAlert[] = null;

    @Type(() => ContractClient)
    loginClients: ContractClient[] = [];

    hasGoodState(): boolean {
        return ContractStatusConstants.Goods.includes(this.stato);
    }

    isClosedState(): boolean {
        return ContractStatusConstants.Bads
            .includes(this.stato);
    }

    isActiveState(): boolean {
        return [ContractStateExpanded.Working, ContractStateExpanded.Active].includes(this.stato);
    }

    isKoState(): boolean {
        return this.stato === ContractStateExpanded.Ko;
    }

    isRequiringState(): boolean {
        return this.stato === ContractStateExpanded.Requiring;
    }


    getClient(): Client {
        return this.loginClients?.length > 0 ? this.loginClients[0].client : null;
    }

    get contractHasIp() {
        return !this.loginName.includes('VOX');
    }

    static getStringifier(level: number): Contract | null {
        if (level < 0) {
            return null;
        }
        return {
            contractId: null,
            loginName: null,
            parentContractId: null,
            presso: null,
            dal: null,
            al: null,
            hlan: null,
            cf: null,
            piva: null,
            indirizzo: null,
            citta: null,
            provincia: null,
            stato: null,
            stateSimplified: null,
            bts: null,
            famiglia: null,
            voce: null,
            mac: null,
            servizioId: null,
            servizio: null,
            profilo: null,
            added: null,
            note: null,
            contractNotes: null,
            ts: null,
            provider: null,
            codcon: null,
            easyName: null,
            ticketMode: null,
            ticketDescription: null,
            doCoverage: null,
            loginMaster: null,
            ultimoIp: null,
            servizioNavigation: ContractService.getStringifier(level - 1),
            tipoUltimoIp: null,
            ultimaClasseIp: null,
            ultimoRadixIp: null,
            ultimoGatewayIp: null,
            primoIndirizzoDispPerUltimoIp: null,
            ultimoIndirizzoDispPerUltimoIp: null,
            broadcastUltimoIp: null,
            numeroIndirizziPerUltimoIp: null,
            subnetUltimoIp: null,
            protocolloConfigurato: null,
            ipConfigurato: null,
            portaConfigurata: null,
            linkInfrastruttura: null,
            dataUltimoIp: null,
            acs2: null,
            linkTecnico: null,
            linkAmministrativo: null,
            loginPrimaria: null,
            codiceDelivery: null,
            ticketOverride: null,
            contractRouter: LoginRouter.getStringifier(level - 1),
            zucchettiContract: ZucchettiContract.getStringifier(level - 1),
            maintenanceAlerts: null,
            loginClients: null,
        } as Contract;
    }
}

export enum ContractStateExpanded {
    Unknown = 'Unknown',
    WithoutState = 'WithoutState',
    Active = 'Active',
    Closed = 'Closed',
    ClosedEng = 'ClosedEng',
    Requiring = 'Requiring',
    Working = 'Working',
    Canceled = 'Canceled',
    Disused = 'Disused',
    Ko = 'Ko',
    Canceling = 'Canceling',
    ActivationKo = 'ActivationKo',
}

export enum ContractState {
    Unknown = 0,
    Closed = 1,
    Activating = 2,
    Active = 3,
    Ko = 4,
}

// TODO: Otteniamo l'elenco degli stati positivi,negativi,ecc. da API? - PHX-363
export const ContractStatusConstants = {
    All: Object.keys(ContractStateExpanded),
    Goods: [
        ContractStateExpanded.WithoutState,
        ContractStateExpanded.Working,
        ContractStateExpanded.Active,
        ContractStateExpanded.Requiring
    ],
    Closed: [
        ContractStateExpanded.Closed, ContractStateExpanded.Canceled, ContractStateExpanded.Canceling,
        ContractStateExpanded.Disused, ContractStateExpanded.ActivationKo, ContractStateExpanded.ClosedEng
    ],
    Requiring: [
        ContractStateExpanded.Working, ContractStateExpanded.Requiring
    ],
    Actives: [
        ContractStateExpanded.Active
    ],
    Bads: [
        ContractStateExpanded.Ko, ContractStateExpanded.Canceling, ContractStateExpanded.ClosedEng, ContractStateExpanded.Closed,
        ContractStateExpanded.Canceled, ContractStateExpanded.Disused, ContractStateExpanded.ActivationKo,
    ],
    Unusual: [
        ContractStateExpanded.Unknown,
        ContractStateExpanded.WithoutState
    ],
};

export class ContractNote {
    idnote: number;
    noteLogin: string;
    noteUser: string;
    @Type(() => Date)
    createdAt: Date;
    noteMsg: string;
}
