import {Type} from 'class-transformer';
import {Stringifier} from 'src/app/services/stringifier.service';
import {Contract} from '../contract';
import {DeviceCustomFieldType, IDeviceCustomFieldType} from './DeviceCustomFieldType';
import {DeviceTemplate, IDeviceTemplate} from './DeviceTemplate';
import {INetworkInterface, NetworkInterface} from './NetworkInterface';
import {InternalTypeEnum} from './NetworkInterfaceTemplate';

// tslint:disable-next-line:no-empty-interface
export interface IDevice extends Device {
}

@Stringifier
export class Device {
    deviceId: number;
    name: string;

    contractId: number;
    @Type(() => Contract)
    contract?: Contract;

    deviceTemplateId: number;
    @Type(() => DeviceTemplate)
    deviceTemplate?: IDeviceTemplate;

    isDeleted: boolean;

    @Type(() => NetworkInterface)
    networkInterfaces: INetworkInterface[];
    @Type(() => DeviceCustomFieldType)
    deviceCustomFieldTypes: IDeviceCustomFieldType[];

    get userDefinedNetworkInterfaces() {
        return this.networkInterfaces?.filter(ni => ni.isUserDefined) ?? [];
    }

    get selfConnection() {
        return this.networkInterfaces?.filter(ni => ni.isSelfConnection)[0];
    }

    static getStringifier(level: number): Device | null {
        if (level < 0) {
            return null;
        }
        return {
            deviceId: null,
            name: null,
            contractId: null,
            contract: Contract.getStringifier(level - 1),
            deviceTemplateId: null,
            deviceTemplate: DeviceTemplate.getStringifier(level - 1),
            isDeleted: null,
            networkInterfaces: null,
            deviceCustomFieldTypes: null,
        } as Device;
    }
}



