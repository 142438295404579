import {Type} from 'class-transformer';
import {Stringifier} from '../../services/stringifier.service';
import {DeviceTemplate, IDeviceTemplate} from './DeviceTemplate';
import {INetworkInterfaceTemplate, NetworkInterfaceTemplate} from './NetworkInterfaceTemplate';

// tslint:disable-next-line:no-empty-interface
export interface IDeviceTemplateNetworkInterfaceTemplate extends DeviceTemplateNetworkInterfaceTemplate {
}

@Stringifier
export class DeviceTemplateNetworkInterfaceTemplate {
    deviceTemplateNetworkInterfaceTemplateId: number;
    deviceTemplateId: number;
    networkInterfaceTemplateId: number;
    name: string;

    @Type(() => DeviceTemplate)
    deviceTemplate?: IDeviceTemplate;
    @Type(() => NetworkInterfaceTemplate)
    networkInterfaceTemplate?: INetworkInterfaceTemplate;

    static getStringifier(level: number): DeviceTemplateNetworkInterfaceTemplate | null {
        if (level < 0) {
            return null;
        }
        return {
            deviceTemplateNetworkInterfaceTemplateId: null,
            deviceTemplateId: null,
            networkInterfaceTemplateId: null,
            name: null,
            deviceTemplate: DeviceTemplate.getStringifier(level - 1),
            networkInterfaceTemplate: NetworkInterfaceTemplate.getStringifier(level - 1),
        } as DeviceTemplateNetworkInterfaceTemplate;
    }
}
