import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TicketGroupDto} from '../../../models/responses/ticket-group-dto';
import {Provider} from '../../../models/enums/provider';
import {FilterTicketRequest} from '../../../models/requests/filter-ticket-request';
import {StringifierService} from '../../../services/stringifier.service';
import {EnumFilterEntry, EnumFilterEntryGroup} from '../../table/filters/enum/enum-filter.component';
import {ClientTicketStateItems} from '../../../models/enums/ticket-states';
import {ProviderPipe} from '../../../pipes/provider.pipe';
import {TicketType} from '../../../models/responses/ticket-type';
import {TicketTypePipe} from '../../../pipes/ticket-type.pipe';
import {Role} from '../../../models/user-data';
import {RestApiService} from '../../../services/api';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {LazyLoadEvent} from 'primeng/api';
import {Table} from 'primeng/table';

const Columns = ['unread', 'flag', 'createdAt', 'updatedAt', 'user', 'clientTicket', 'providerTicket', 'clientTitle', 'providerTitle',
    'type', 'state', 'provider', 'contract', 'line', 'clientName', 'lastClientMessageDate'];
export type TicketsTableColumns = typeof Columns[number];

@Component({
    selector: 'app-tickets-table',
    templateUrl: './tickets-table.component.html',
    styleUrls: ['./tickets-table.component.scss']
})
export class TicketsTableComponent implements OnInit {

    static readonly columns = Columns as TicketsTableColumns[];

    constructor(private api: RestApiService,
                private stringifier: StringifierService) {
        this.filterStringifier = stringifier.getStringifier(FilterTicketRequest);
    }

    @Input()
    loading = false;

    @Input()
    pagination = true;

    @Input()
    showFilters = true;

    @Input()
    tickets: ListedResponse<TicketGroupDto> = {dataList: [], count: 0};

    @Input()
    filterRequest: FilterTicketRequest = {} as FilterTicketRequest;

    @Input()
    columnsToShow: TicketsTableColumns[] = [];

    @Output()
    triggerLazyLoad = new EventEmitter<LazyLoadEvent>();

    @ViewChild('table')
    tableRef: Table;

    get filters() {
        return this.tableRef.filters;
    }

    filterStringifier: FilterTicketRequest;

    // Lista degli stati di un ticket
    ticketStates: EnumFilterEntry[] = ClientTicketStateItems.map(state => <EnumFilterEntry>{
        key: state.code, label: state.description
    });

    // Lista dei provider
    providers: EnumFilterEntry[] = Object.values(Provider).map(value => ({
        key: value, label: ProviderPipe.transform(value)
    }));

    ticketTypes: EnumFilterEntry[] = [
        {key: TicketType.Technical, label: TicketTypePipe.transform(TicketType.Technical)},
        {key: TicketType.Administrative, label: TicketTypePipe.transform(TicketType.Administrative)}
    ];

    /**
     * Lista degli utenti da mostrare nel filtro utenti
     */
    users: EnumFilterEntryGroup[] = [];
    /**
     * Mostra gli utenti inattivi nel filtro utenti
     */
    showInactiveUsers = false;

    public obj(item: TicketGroupDto) {
        return item;
    }

    ngOnInit(): void {
        this.loadUsersList(this.showInactiveUsers);

        if (!this.columnsToShow || this.columnsToShow.length === 0) {
            this.columnsToShow = TicketsTableComponent.columns;
        }
    }

    distinctTicketProviders(ticketGroup: TicketGroupDto) {
        return [...new Set([
            ...ticketGroup.providerTickets.map(t => t.internalProvider ? Provider.MFT : t.contract?.provider),
            ...ticketGroup.clientTickets.map(t => t.contract?.provider),
        ])];
    }

    distinctTicketContracts(ticketGroup: TicketGroupDto) {
        const contracts = {};
        for (const ticket of ticketGroup.providerTickets) {
            if (ticket.contract) {
                contracts[ticket.contract.loginName] = ticket.contract;
            }
        }
        for (const ticket of ticketGroup.clientTickets) {
            if (ticket.contract) {
                contracts[ticket.contract.loginName] = ticket.contract;
            }
        }
        return Object.values(contracts);
    }

    async loadUsersList(showInactiveUsers) { // TODO: unificabile?
        this.users = [{
            group: 'Utenti',
            entries: (await this.api.usersCached.getAll(!showInactiveUsers)).data
                .filter(u => u.role !== Role.CommercialAgent)
                .sort((u1, u2) => (u1.cognome ?? 'ZZZ').localeCompare(u2.cognome))
                .map(u => ({key: u.codice, label: u.fullName}))
        }, {
            group: 'Gruppi',
            entries: (await this.api.userGroupsCached.getAll()).data
                .sort((u1, u2) => (u1.groupLabel ?? 'ZZZ').localeCompare(u2.groupLabel))
                .map(u => ({key: u.groupCodice, label: u.groupLabel}))
        }];

        this.showInactiveUsers = showInactiveUsers;
    }

    showColumn(column: TicketsTableColumns) {
        return this.columnsToShow.includes(column);
    }

    static openPrefilteredTicketList(field: string, value: string) {
        const url = location.origin + `/tickets?${field}=${value}`;
        window.open(url, '_blank');
    }


}
