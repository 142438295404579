import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MenuInterface} from '../../../../../components/menu/menu-interface';
import {CriticalTicketsOptions} from '../../../../../models/tickets/critical-tickets-options';
import {RestApiService} from '../../../../../services/api';
import {AclPermission} from '../../../../../models/acl/acl-group';
import {ToastService} from '../../../../../services/toast.service';
import {Role} from '../../../../../models/user-data';
import {EnumFilterEntryGroup} from '../../../../../components/table/filters/enum/enum-filter.component';

@Component({
    selector: 'app-critical-tickets-menu',
    templateUrl: './critical-tickets-menu.component.html',
    styleUrls: ['./critical-tickets-menu.component.scss']
})
export class CriticalTicketsMenuComponent implements OnInit, MenuInterface {

    AclPermission = AclPermission;

    data: any;

    options: CriticalTicketsOptions = {} as CriticalTicketsOptions;
    showEditOptions = false;
    showInactiveUsers = false;
    usersList: EnumFilterEntryGroup[] = [];

    @Output()
    saveOptions = new EventEmitter<CriticalTicketsOptions>();

    constructor(
        private api: RestApiService,
        private toast: ToastService,
        ) {
    }

    ngOnInit(): void {
        this.loadUsersList(this.showInactiveUsers);
    }

    async editCriticalTicketsListSettings() {
        try {
            const response = await this.api.tickets.getCriticalTicketsOptions();
            this.options = response.data;
            this.showEditOptions = true;
        } catch (e) {
            this.toast.error('Errore durante il caricamento delle opzioni', `Non è stato possibile caricare le opzioni: ${e}`);
        }
    }

    async save() {
        try {
            await this.api.tickets.setCriticalTicketsOptions(this.options);
            this.toast.success('Opzioni salvate', 'Le opzioni sono state salvate correttamente');
            this.showEditOptions = false;
            this.saveOptions.emit(this.options);
        } catch (e) {
            this.toast.error('Errore durante il salvataggio delle opzioni', `Non è stato possibile salvare le opzioni: ${e}`);
        }
    }


    async loadUsersList(showInactiveUsers) {
        this.usersList = [{
            group: 'Utenti',
            entries: (await this.api.usersCached.getAll(!showInactiveUsers)).data
                .filter(u => u.role !== Role.CommercialAgent)
                .sort((u1, u2) => (u1.cognome ?? 'ZZZ').localeCompare(u2.cognome))
                .map(u => ({key: u.codice, label: u.fullName}))
        }, {
            group: 'Gruppi',
            entries: (await this.api.userGroupsCached.getAll()).data
                .sort((u1, u2) => (u1.groupLabel ?? 'ZZZ').localeCompare(u2.groupLabel))
                .map(u => ({key: u.groupCodice, label: u.groupLabel}))
        }];

        this.showInactiveUsers = showInactiveUsers;
    }
}
