import {Pipe, PipeTransform} from '@angular/core';
import {TicketType} from '../models/responses/ticket-type';
import {Provider} from '../models/enums/provider';
import {ClientTicketState} from '../models/enums/ticket-states';

@Pipe({name: 'ticketState'})
export class TicketStatePipe implements PipeTransform {
    static transform(value: ClientTicketState | string) {
        switch (value) {
            case ClientTicketState.Open: return 'Aperto';
            case ClientTicketState.Closed: return 'Chiuso';
            case ClientTicketState.ClosedByClient: return 'Chiuso (cliente)';
            case ClientTicketState.Working: return 'In lavorazione';
            case ClientTicketState.Resolving: return 'In risoluzione';
            case ClientTicketState.Unknown: return 'Nessuno stato';
            default: return '';
        }
    }

    transform(value: ClientTicketState | string): string {
        return TicketStatePipe.transform(value);
    }
}
