<p-blockUI [target]="content" [blocked]="loadingAccessDetails">
    <p-progressSpinner></p-progressSpinner>
</p-blockUI>

<app-blockable-div #content>
    <div *ngIf="!!accessLog" class="flex flex-column">

        <p-message severity="error" *ngIf="loadingError; else logDetails">
            Non è stato possibile caricare i log di accesso del cliente
        </p-message>

        <ng-template #logDetails>

            <div class="w-full">
                <p-message styleClass="w-full inline-block" severity="info" [text]="accessLog.accessFromApp
                    ? 'Il cliente ha già effettuato l\'accesso tramite l\'app'
                    : 'Il cliente non ha mai effettuato l\'accesso tramite l\'app'"></p-message>
            </div>
            <p-table
                [totalRecords]="accessLog.accessLogs.length"
                [value]="accessLog.accessLogs"
                [rows]="20"
                class="mt-5"
                [paginator]="true"
                [rowHover]="true"
                styleClass="p-datatable-striped p-datatable-sm"
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th>Data accesso</th>
                        <th>Account accesso</th>
<!--                        <th>IP accesso</th>-->
                        <th>Tipo accesso</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{obj(item).accessDateTime | appDate:'medium'}}</td>
                        <td><span *ngIf="!obj(item).technician">Account {{obj(item).client.username}}</span>
                            <span *ngIf="obj(item).technician">Account tecnico {{obj(item).technician.user}}</span></td>
<!--                        <td>{{obj(item).ipAddress}}</td>-->
                        <td>{{obj(item).accessDeviceType}}</td>
                    </tr>
                </ng-template>

            </p-table>

        </ng-template>

    </div>
</app-blockable-div>
