import {User} from './user';
import {AclPermission} from './acl/acl-group';

export interface UserData extends User {
    token: string;
    role: Role;
    phpGroups: string[];
}

export enum Role {
    Client = 1,
    Reseller = 10,
    CommercialAgent = 20
}
