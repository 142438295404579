import {BaseService, Body, GET, Headers, PATCH, Path, POST, Query, Response, PUT, DELETE} from 'ts-retrofit';

import {ContractViewModel} from '../../../../models/contracts/contract-view.model';
import {ContractRouterParameters} from '../../../../models/contracts/contract-router-parameters';
import {ACCEPT_JSON, serviceFactory} from '../../config';
import {BodyRequest} from '../../../../models/body-request';
import {Connection, LineIpDetailsResponse} from '../../../../models/responses/line-ip-details-response';
import {ContractCoverage} from '../../../../models/contract-coverage';
import {ToData, ToListedResponse} from '../../rest-api-hydratator-decorators';
import {ZucchettiContract} from '../../../../models/zucchetti-contract';
import {LoginRouter} from '../../../../models/login-router';
import {ContractNotesService} from './contract-notes.service';
import {contracts} from './contracts-service-constants';
import {MaintenanceAlert} from '../../../../models/maintenance-alert';
import {FilterContractRequest} from '../../../../models/requests/filter-contract-request';
import {Contract, ContractStateExpanded} from '../../../../models/contract';
import {ListedResponse} from '../../../../models/hydratator/listed-response';


export class ContractsService extends BaseService {

    contractNotes = serviceFactory(ContractNotesService);

    @PATCH(`/${contracts}/{contractId}/note`)
    @Headers({...ACCEPT_JSON})
    async updateNote(@Path('contractId') contractId, @Body request: BodyRequest<string>): Promise<Response> {
        return <Response>{};
    }

    @PATCH(`/${contracts}/{contractId}/easyName`)
    @Headers({...ACCEPT_JSON})
    async updateEasyName(@Path('contractId') contractId, @Body request: BodyRequest<string>): Promise<Response> {
        return <Response>{};
    }

    @GET(`/${contracts}/{contractId}/ip`)
    @Headers({...ACCEPT_JSON})
    async getIpDetails(
        @Path('contractId') contractId: number,
        @Query('onlyLastIp') onlyLastIp: boolean,
        @Query('forceReload') forceReload: boolean = false
    ):
        Promise<Response<LineIpDetailsResponse>> {
        return <Response>{};
    }

    @GET(`/${contracts}/{contractId}/history`)
    @Headers({...ACCEPT_JSON})
    async getSessionHistory(@Path('contractId') contractId: number): Promise<Response<Connection[]>> {
        return <Response>{};
    }

    @PATCH(`/${contracts}/{contractId}/loginPassword`)
    async refreshLoginPassword(@Path('contractId') contractId: number): Promise<Response> {
        return <Response>{};
    }

    @GET(`/${contracts}/{contractId}/coverages`)
    @Headers({...ACCEPT_JSON})
    @ToData(ContractCoverage)
    async getLastCoveragesStatus(@Path('contractId') contractId: number, @Query('amount') amount = 1)
        : Promise<Response<ContractCoverage[]>> {
        return <Response>{};
    }

    @POST(`/coverage/login/{loginName}`)
    @Headers({...ACCEPT_JSON})
    async updateCoverage(@Path('loginName') loginName: string): Promise<Response> {
        return <Response>{};
    }

    @GET(`/${contracts}/{contractId}/zucchetti`)
    @Headers({...ACCEPT_JSON})
    @ToData(ZucchettiContract)
    async getZucchettiContract(@Path('contractId') contractId: number)
        : Promise<Response<ZucchettiContract>> {
        return <Response>{};
    }

    @GET(`/${contracts}/{contractId}/router`)
    @Headers({...ACCEPT_JSON})
    @ToData(LoginRouter)
    async getRouterData(@Path('contractId') contractId: number)
        : Promise<Response<LoginRouter>> {
        return <Response>{};
    }

    @POST(`/${contracts}/zucchetti`)
    @Headers({...ACCEPT_JSON})
    @ToData(ZucchettiContract)
    async updateZucchettiContract(@Body zucchettiContract: ZucchettiContract): Promise<Response<ZucchettiContract>> {
        return <Response>{};
    }

    @GET(`/${contracts}/{contractId}/scheduledMaintenances`)
    @Headers({...ACCEPT_JSON})
    @ToData(MaintenanceAlert)
    async getScheduledMaintenances(@Path('contractId') contractId: number): Promise<Response<MaintenanceAlert[]>> {
        return <Response>{};
    }

    @POST(`/${contracts}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Contract)
    async getContractsList(@Body request: FilterContractRequest): Promise<Response<ListedResponse<Contract>>> {
        return <Response>{};
    }

    @GET(`/${contracts}/{contractId}/router/parameters`)
    @Headers({...ACCEPT_JSON})
    @ToData(ContractRouterParameters)
    async getParameters(@Path('contractId') contractId: number): Promise<Response<ContractRouterParameters>> {
        return <Response>{};
    }

    @POST(`/${contracts}/{contractId}/router/parameters`)
    @Headers({...ACCEPT_JSON})
    @ToData(ContractRouterParameters)
    async updateParameters(@Path('contractId') contractId: number, @Body contractParameters: ContractRouterParameters)
        : Promise<Response<ContractRouterParameters>> {
        return <Response>{};
    }

    @GET(`/${contracts}/helperData`)
    @Headers({...ACCEPT_JSON})
    @ToData(LoginRouter)
    async getHelperData()
        : Promise<Response<{services: {name: string, nameOld: string, id: number}[], providers: string[]}>> {
        return <Response>{};
    }

    @PUT(`/${contracts}/{contractId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async editContract(@Path('contractId') contractId: number, @Body contract: ContractViewModel)
        : Promise<Response<Contract>> {
        return <Response>{};
    }

    @POST(`/${contracts}`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async addContract(@Body contract: ContractViewModel)
        : Promise<Response<Contract>> {
        return <Response>{};
    }

    @POST(`/${contracts}/{contractId}/clone`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async cloneContract(@Path('contractId') contractId: number, @Body contract: ContractViewModel)
        : Promise<Response<Contract>> {
        return <Response>{};
    }

    @POST(`/${contracts}/{contractId}/parent`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async setParent(@Path('contractId') contractId: number, @Body parentId: BodyRequest<number>): Promise<Response<Contract>> {
        return <Response>{};
    }

    @DELETE(`/${contracts}/{contractId}/parent`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async removeParent(@Path('contractId') contractId: number): Promise<Response<Contract>> {
        return <Response>{};
    }
}

