import {Component, Input} from '@angular/core';
import {MenuInterface} from '../../../../../components/menu/menu-interface';
import {SessionStorageService} from '../../../../../services/session-storage.service';
import {AclPermission} from '../../../../../models/acl/acl-group';
import {Routing} from '../../../../../models/routing/routing';
import {getMftwebUrl, openMftwebUrl} from '../../../../mftweb/mftweb/mftweb.component';

@Component({
    selector: 'app-menu',
    templateUrl: './tickets-list-menu.component.html',
    styleUrls: ['./tickets-list-menu.component.scss']
})
export class TicketsListMenuComponent implements MenuInterface {
    @Input() data: any;

    constructor(private sessionService: SessionStorageService) {
    }

    newTicketFromLogin(login: string) {
        const url = !this.sessionService.hasPermission(AclPermission.PhoenixTicketManagement)
            ? getMftwebUrl(`/Ticket/Ticket.php?new=${login.trim()}`)
            : Routing.tickets.new(null, login.trim());
        window.open(url, '_blank');
    }

    newInternalTicket() {
        openMftwebUrl('/Ticket/Ticket.php?idCliente=0&newInt');
    }

    searchContent(text: string) {
        window.open(Routing.tickets.contentSearch(text.trim()), '_blank');
    }

    get editLinesColorsUrl() {
        return getMftwebUrl('/Ticket/List/TkLinee.php');
    }

}
